import axios from 'axios'
import { stringify } from 'qs'
import { replace } from '../../helpers/immutable'
import { setSnackbar } from './snackbar'

const initialState = {
  list: [],
  organisation: {},
  isOpen: false,
  filter: {
    orderBy: null,
    order: 'asc',
    query: null
  },
  isLoadingCreateOrUpdate: false,
  isLoadingFetch: false,
  isLoadingRemove: false,
  error: null
}

/* TOGGLE_MODAL Action */
const TOGGLE_MODAL = 'admin/TOGGLE_MODAL'
export const toggle = () => ({
  type: TOGGLE_MODAL
})

/* HANDLE_CHANGE Action */
const HANDLE_CHANGE = 'admin/HANDLE_CHANGE'
export const handleChange = organisation => ({
  type: HANDLE_CHANGE,
  payload: organisation
})

/* FETCH_WITH_FILTER Action */
const HANDLE_FILTER = 'admin/HANDLE_FILTER'
export const handleFilter = filter => ({
  type: HANDLE_FILTER,
  payload: filter
})

/* FETCH_ALL Action */
const FETCH_ALL_START = 'admin/FETCH_ALL_START'
const fetchAllStart = () => ({
  type: FETCH_ALL_START
})

const FETCH_ALL_FAIL = 'admin/FETCH_ALL_FAIL'
const fetchAllFail = err => ({
  type: FETCH_ALL_FAIL,
  payload: err
})

const FETCH_ALL_SUCCESS = 'admin/FETCH_ALL_SUCCESS'
const fetchAllSuccess = list => ({
  type: FETCH_ALL_SUCCESS,
  payload: list
})

export const fetchAll = newFilter => async (dispatch, getState) => {
  const { filter } = getState().admin
  let sort = stringify(filter)
  if (newFilter) {
    sort = stringify(newFilter)
    dispatch(handleFilter(newFilter))
  }
  const url = `/v1/organisations?${sort}`
  dispatch(fetchAllStart())
  try {
    const response = await axios.get(url)
    dispatch(fetchAllSuccess(response.data))
  } catch (err) {
    dispatch(fetchAllFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* CREATE_ORGANISATION Action */
const CREATE_ORGANISATION_START = 'CREATE_ORGANISATION_START'
const createOrganisationStart = () => ({
  type: CREATE_ORGANISATION_START
})

const CREATE_ORGANISATION_FAIL = 'CREATE_ORGANISATION_FAIL'
const createOrganisationFail = err => ({
  type: CREATE_ORGANISATION_FAIL,
  payload: err
})

const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS'
const createOrganisationSuccess = organisation => ({
  type: CREATE_ORGANISATION_SUCCESS,
  payload: organisation
})

export const createOrganisation = body => async dispatch => {
  dispatch(createOrganisationStart())
  try {
    const response = await axios.post('/v1/organisations', body)
    dispatch(createOrganisationSuccess(response.data))
    dispatch(setSnackbar(`${response.data.name} har skapats.`, 'success'))
  } catch (err) {
    dispatch(createOrganisationFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* EDIT_ORGANISATION Action */
const EDIT_ORGANISATION_START = 'EDIT_ORGANISATION_START'
const editOrganisationStart = () => ({
  type: EDIT_ORGANISATION_START
})

const EDIT_ORGANISATION_FAIL = 'EDIT_ORGANISATION_FAIL'
const editOrganisationFail = err => ({
  type: EDIT_ORGANISATION_FAIL,
  payload: err
})

const EDIT_ORGANISATION_SUCCESS = 'EDIT_ORGANISATION_SUCCESS'
const editOrganisationSuccess = body => ({
  type: EDIT_ORGANISATION_SUCCESS,
  payload: body
})

export const editOrganisation = organisation => async dispatch => {
  dispatch(editOrganisationStart())
  try {
    const response = await axios.put(
      `/v1/organisations/${organisation.id}`,
      organisation
    )
    dispatch(editOrganisationSuccess(response.data))
    dispatch(setSnackbar(`${response.data.name} har uppdaterats.`, 'success'))
  } catch (err) {
    dispatch(editOrganisationFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REMOVE_ORGANISATION Action */
const REMOVE_ORGANISATION_START = 'REMOVE_ORGANISATION_START'
const removeOrganisationStart = () => ({
  type: REMOVE_ORGANISATION_START
})

const REMOVE_ORGANISATION_FAIL = 'REMOVE_ORGANISATION_FAIL'
const removeOrganisationFail = err => ({
  type: REMOVE_ORGANISATION_FAIL,
  payload: err
})

const REMOVE_ORGANISATION_SUCCESS = 'REMOVE_ORGANISATION_SUCCESS'
const removeOrganisationSuccess = id => ({
  type: REMOVE_ORGANISATION_SUCCESS,
  payload: id
})

export const removeOrganisation = organisation => async dispatch => {
  dispatch(removeOrganisationStart())
  try {
    await axios.delete(`/v1/organisations/${organisation.id}`)
    dispatch(removeOrganisationSuccess(organisation.id))
    dispatch(setSnackbar(`${organisation.name} har tagits bort.`, 'success'))
  } catch (err) {
    dispatch(removeOrganisationFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const organisations = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen }
    case HANDLE_CHANGE:
      return { ...state, organisation: action.payload }
    case HANDLE_FILTER:
      return { ...state, filter: action.payload }
    case FETCH_ALL_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ALL_FAIL:
      return { ...state, isLoadingFetch: false, error: action.payload }
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isLoadingFetch: false,
        list: action.payload,
        error: null
      }
    case CREATE_ORGANISATION_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_ORGANISATION_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case CREATE_ORGANISATION_SUCCESS: {
      const list = [...state.list]
      list.push(action.payload)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        organisation: {},
        list,
        isOpen: false,
        error: null
      }
    }
    case EDIT_ORGANISATION_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case EDIT_ORGANISATION_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case EDIT_ORGANISATION_SUCCESS: {
      const list = replace({ id: action.payload.id }, action.payload, [
        ...state.list
      ])
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        organisation: {},
        list,
        isOpen: false,
        error: null
      }
    }
    case REMOVE_ORGANISATION_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_ORGANISATION_FAIL:
      return {
        ...state,
        isLoadingRemove: false,
        error: action.payload
      }
    case REMOVE_ORGANISATION_SUCCESS: {
      const list = replace({ id: action.payload }, null, [...state.list])
      return {
        ...state,
        isLoadingRemove: false,
        organisation: {},
        list,
        error: null
      }
    }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default organisations
