import { UpdateEvent } from 'src/components/UpdateOverlay'
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = false
// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.0/8 are considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// )

const registerPwaOpeningHandler = () => {
  // console.log('registerPwaOpeningHandler')
  let hidden
  let visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  window.document.addEventListener(visibilityChange, () => {
    if (!document[hidden]) {
      register({ immediate: true })
    }
  })
}
registerPwaOpeningHandler()

export function register(config) {
  try {
    // console.log('register', config)
    if (
      /* process.env.NODE_ENV === 'production && */ 'serviceWorker' in navigator
    ) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
      if (publicUrl.origin !== window.location.origin) {
        return
      }

      if (config && config.immediate) {
        doRegister(config)
      } else {
        window.addEventListener('load', () => doRegister(config))
      }
    }
  } catch (e) {
    console.error('ServiceWorker error', e)
  }
}

function doRegister(config) {
  // console.log('doRegister', config)
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

  if (isLocalhost) {
    checkValidServiceWorker(swUrl, config)
    navigator.serviceWorker.ready.then(() => {})
  } else {
    registerValidSW(swUrl, config)
  }
}

const onUpdateFound = (registration, config) => {
  // console.log('onUpdateFound', registration, config)
  const installingWorker = registration.installing
  if (installingWorker == null) {
    return
  }
  // window.dispatchEvent(UpdateEvent())
  installingWorker.onstatechange = () => {
    if (installingWorker.state === 'installed') {
      if (navigator.serviceWorker.controller) {
        if (config && config.onUpdate) {
          config.onUpdate(registration)
        }
      } else if (config && config.onSuccess) {
        config.onSuccess(registration)
      }
    } else if (installingWorker.state === 'activated') {
      // window.location.reload(true)
    }
  }
}
function registerValidSW(swUrl, config) {
  // console.log('registerValidSW', swUrl, config)
  navigator.serviceWorker
    .getRegistration(swUrl)
    .then(registration => {
      if (registration) {
        registration.onupdatefound = () => onUpdateFound(registration, config)
        return registration.update()
      }
      navigator.serviceWorker
        .register(swUrl)
        .then(registration2 => {
          registration2.onupdatefound = () =>
            onUpdateFound(registration2, config)
        })
        .catch(error => {
          console.error('Error during service worker registration:', error)
        })
    })
    .catch(error => {
      console.error('serviceWorker.getRegistration() error', error)
    })
}

function checkValidServiceWorker(swUrl, config) {
  // console.log('checkValidServiceWorker', swUrl, config)
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then(response => {
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            // window.location.reload()
          })
        })
      } else {
        registerValidSW(swUrl, config)
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
