import * as Sentry from '@sentry/react'
import axios from 'axios'
import { push } from 'connected-react-router'
import { setSnackbar } from './snackbar'

const initialState = {
  user: null,
  token: null,
  expires: null,
  showInstall: true
}

/* Validate stored values and set initial user state */
const storedUser = localStorage.getItem('user')
const storedToken = localStorage.getItem('token')
const storedExpires = localStorage.getItem('expires')
if (storedUser && storedToken && storedExpires) {
  const now = new Date().getTime() / 1000
  const nExpires = parseInt(storedExpires, 10)
  if (now < nExpires) {
    try {
      initialState.user = JSON.parse(storedUser)
      initialState.token = storedToken
      initialState.expires = nExpires
      initialState.showInstall = false
    } catch (e) {
      console.error(e)
    } /* silently fail parsing error */
  }
}

/* TOGGLE_FORGOT_MODEL Action */

const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const toggle = () => ({
  type: TOGGLE_MODAL
})

/* TOGGLE INSTALLATION MODAL */
const TOGGLE_INSTALLATION_MODAL = 'TOGGLE_INSTALLATION_MODAL'
export const toggleInstallationModal = show => ({
  type: TOGGLE_INSTALLATION_MODAL,
  payload: show
})

/* UPDATE OWN USER */
const UPDATE_OWN_USER = '@login/update-own-user'
export const updateOwnUser = user => ({
  type: UPDATE_OWN_USER,
  payload: user
})

/* HANDLE_CHANGE Action */

const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const handleChange = (email, password) => ({
  type: HANDLE_CHANGE,
  payload: { email, password }
})

/* SEND_RECOVERY_EMAIL Action */

const SEND_RECOVERY_EMAIL_START = 'SEND_RECOVERY_EMAIL_START'
const sendRecoveryEmailStart = () => ({
  type: SEND_RECOVERY_EMAIL_START
})

const SEND_RECOVERY_EMAIL_FAIL = 'SEND_RECOVERY_EMAIL_FAIL'
const sendRecoveryEmailFail = error => ({
  type: SEND_RECOVERY_EMAIL_FAIL,
  payload: error
})

const SEND_RECOVERY_EMAIL_SUCCESS = 'SEND_RECOVERY_EMAIL_SUCCESS'
const sendRecoveryEmailSuccess = () => ({
  type: SEND_RECOVERY_EMAIL_SUCCESS
})

export const sendRecoveryEmail = email => async dispatch => {
  dispatch(sendRecoveryEmailStart())
  try {
    await axios.post('/v1/users/recover', { email })
    dispatch(sendRecoveryEmailSuccess())
  } catch (err) {
    dispatch(sendRecoveryEmailFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* HANDLE_SUBMIT Action */

const HANDLE_SUBMIT_START = 'HANDLE_SUBMIT_START'
const handleSubmitStart = () => ({
  type: HANDLE_SUBMIT_START
})

const HANDLE_SUBMIT_FAIL = 'HANDLE_SUBMIT_FAIL'
const handleSubmitFail = error => ({
  type: HANDLE_SUBMIT_FAIL,
  payload: error
})

const HANDLE_SUBMIT_SUCCESS = 'HANDLE_SUBMIT_SUCCESS'
// Data contains email and password
const handleSubmitSuccess = data => ({
  type: HANDLE_SUBMIT_SUCCESS,
  payload: data
})

const SET_AUTHENTICATION_DATA = 'SET_AUTHENTICATION_DATA'
export const setAuthenticationData = ({ user, token, expires }) => ({
  type: SET_AUTHENTICATION_DATA,
  payload: { user, token, expires }
})

const SIGNOUT = 'SIGNOUT'
export const signout = () => ({
  type: SIGNOUT
})

export const handleSubmit = (email, password) => async dispatch => {
  dispatch(handleSubmitStart())
  try {
    const response = await axios.post('/v1/auth', { email, password })
    dispatch(handleSubmitSuccess(response.data))
    dispatch({ type: 'CLEAR_STATE' })
    dispatch(push('/dashboard'))
  } catch (err) {
    dispatch(handleSubmitFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION_DATA: {
      const { user, token, expires } = action.payload
      try {
        const prevUser = JSON.parse(localStorage.getItem('user'))
        if (prevUser.id !== user.id) {
          sessionStorage.removeItem('key_management_org')
        }
      } catch (e) {
        sessionStorage.removeItem('key_management_org')
      }
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('expires', expires)
      try {
        if (user) {
          Sentry.setUser({
            id: user.id,
            authType: user.authType,
            email: user.email,
            username: user.name,
            roles: user.roles,
            organisation: {
              id: user.organisation.id,
              name: user.organisation.name,
              type: user.organisation.type
            }
          })
        }
      } catch (e) {
        console.error('Sentry.setUser() error:', e)
      }
      return {
        ...state,
        user,
        token,
        expires,
        showInstall: true
      }
    }
    case TOGGLE_INSTALLATION_MODAL: {
      return { ...state, showInstall: action.payload }
    }
    case UPDATE_OWN_USER: {
      const { payload: user } = action
      localStorage.setItem('user', JSON.stringify(user))
      return { ...state, user }
    }
    case SIGNOUT: {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('expires')
      return {
        ...state,
        user: null,
        token: null,
        expires: null
      }
    }
    case HANDLE_CHANGE: {
      const { email, password } = action.payload
      return { ...state, email, password }
    }
    case SEND_RECOVERY_EMAIL_START:
      return {
        ...state,
        isLoadingRecovery: true
      }
    case SEND_RECOVERY_EMAIL_FAIL: {
      const { message, response } = action.payload
      setSnackbar(response ? response.data : message, 'error')
      return {
        ...state,
        isLoadingRecovery: false,
        error: action.payload
      }
    }
    case SEND_RECOVERY_EMAIL_SUCCESS: {
      setSnackbar(
        'En länk för återställning har skickats till din email om du finns registererad hos oss.',
        'success'
      )
      return {
        ...state,
        isLoadingRecovery: false,
        email: '',
        isOpen: false,
        error: null
      }
    }
    case HANDLE_SUBMIT_START:
      return { ...state, isLoadingSubmit: true }
    case HANDLE_SUBMIT_FAIL: {
      const err = action.payload
      let msg
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 404) {
          msg = 'Felaktiga inloggningsuppgifter.'
        } else {
          msg = err.message
        }
        setSnackbar(msg, 'error')
      } else setSnackbar(err.message, 'error')
      return { ...state, error: action.payload, isLoadingSubmit: false }
    }
    case HANDLE_SUBMIT_SUCCESS: {
      const { token, user, expires } = action.payload
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('expires', expires)
      return {
        ...state,
        isLoadingSubmit: false,
        email: '',
        password: '',
        error: null,
        user
      }
    }
    case 'CLEAR_STATE':
      return initialState
    case TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen }
    default:
      return state
  }
}

export default login
