import { useEffect, useState } from 'react'

export const useNetworkStatus = () => {
  const [online, setOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setOnline(true)
    const handleOffline = () => setOnline(false)
    const visibilityListener = () => {
      if (document.visibilityState === 'visible') {
        console.log('visible', navigator.onLine)
        setOnline(navigator.onLine)
      }
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    document.addEventListener('visibilitychange', visibilityListener)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      document.removeEventListener('visibilitychange', visibilityListener)
    }
  }, [])

  return { online }
}
