import _ from 'lodash'

import SE from 'src/localization/SE.json'
import NO from 'src/localization/NO.json'

const Languages = { SE, NO }

const SUPPORTED_LANGUAGES = [
  'SE', // Swedish
  'SV', // Swedish
  'NO', // Norwegian
  'NN', // Nynorska
  'NB' // Norwegian bokmål
]
const FALLBACK_LANGUAGE = SUPPORTED_LANGUAGES[0]

const regex = (language: string) => new RegExp(`([a-z]{2}-)?${language}`, 'i')
export const getLanguage = (): string => {
  // return SUPPORTED_LANGUAGES[2] // Norwegian
  if ('language' in navigator) {
    const { language } = navigator
    /* match navigator language, eg. en-US, sv-SE, sv, no, nn against our list */
    let _language = SUPPORTED_LANGUAGES.find(sl => regex(sl).test(language))
    if (_language) {
      // other fallbacks
      if (_language === 'NN' || _language === 'NB') {
        _language = 'NO'
      } else if (_language === 'SV') {
        _language = 'SE'
      }
      return _language
    }
    // fallback on domain extension (.no/.se)
    const domainExt =
      window?.location?.hostname?.split('.').pop()?.toUpperCase() ?? ''
    if (SUPPORTED_LANGUAGES.includes(domainExt)) {
      return domainExt
    }
  }
  return FALLBACK_LANGUAGE
}

export const translate = (
  keyPath: string,
  substitutions?: string[],
  lang?: string
): string => {
  const language = lang || global.getLanguage()
  if (language !== 'NO' && language !== 'SE') {
    return keyPath
  }

  const map = Languages[language]

  let value = _.get(map, keyPath, keyPath)

  /* string translations */
  if (typeof value === 'object') {
    value = value.$ || keyPath
  }

  if (typeof value === 'string') {
    // Substitutions, e.g $1, $2 etc
    if (Array.isArray(substitutions)) {
      substitutions.forEach((sub, idx) => {
        const subRegExp = new RegExp(`\\$${idx + 1}`, 'g')
        value = value.replace(subRegExp, sub)
      })
    }
    // Replace self references, e.g {{Some.Localization.Key}} with actual value
    // const refs = value.match(/(?<=\{{2}).+?(?=\}{2})/g)
    const refs = []
    let refStartIdx = value.indexOf('{{')
    while (refStartIdx > -1) {
      const refEndIdx = value.indexOf('}}', refStartIdx)
      if (refEndIdx > -1) {
        refs.push(value.substring(refStartIdx + 2, refEndIdx))
      }
      refStartIdx = value.indexOf('{{', refStartIdx + 1)
    }
    refs.forEach(ref => {
      let refValue
      if (ref.startsWith('$')) {
        // ref to a key within own object
        const path = keyPath.split('.')
        path.splice(path.length - 1, 1, ref.replace('$', ''))
        const pathString = path.join('.')
        refValue = _.get(map, pathString, pathString)
      } else {
        // full path ref
        refValue = _.get(map, ref, ref)
      }
      value = value.replace(`{{${ref}}}`, refValue)
    })
  }

  if (!value || value === keyPath) {
    // Check for wildcard in path
    if (keyPath?.split) {
      const split = keyPath.split('.')
      const originalValue = value
      for (let x = split.length - 1; x > 0; x -= 1) {
        const wildcard = `${split.slice(0, x).join('.')}.*`
        value = _.get(map, wildcard, null)
        if (typeof value === 'string') {
          break
        }
      }
      if (typeof value !== 'string') {
        value = originalValue
      }
    }
  }

  return value
}

global._ = translate
global.translate = translate
global.getLanguage = getLanguage
global.getLanguageByDomainExtension = () => {
  const extension =
    window.location.hostname.split('.').pop()?.toUpperCase() ?? ''
  return SUPPORTED_LANGUAGES.includes(extension) ? extension : null
}

/*

# Translation files
Translations are in src/localization/{language}.json


# Substitions
Translations can contain substitions, which are marked with $1, $2 etc. Example:
-----------------------------------
Common: {
  SomeKey: "This is the $1 for $2"
}
-----------------------------------

---- Usage in code --------------------------------------
global._('Common.SomeKey', ['first substitution', 'you'])
---------------------------------------------------------
Result: 'This is the first substitution for you'


# References
Translations can contain references to other translations. Example 1:
-----------------------------------
Common: {
  MyRef: "my ref"
  SomeKey: "Look at {{Common.MyRef}}" <----------- Full path
}
-----------------------------------
global._('Common.SomeKey')
-----------------------------------
Result: 'Look at my ref'

The same can be accomplished using a self reference (prepend with $) as well (if the key is within the same object):
-----------------------------------
Common: {
  MyRef: "my ref"
  SomeKey: "Look at {{$MyRef}}" <--------- will look at Common.MyRef
}
-----------------------------------

You may also use refs within refs:
-----------------------------------
{
  Say: {
    Hello: 'Hello',
    World: 'world'
  }
  Common: {
    MyRef: "{{Say.Hello}}"
    SomeKey: "{{$MyRef}} {{Say.World}}"
  }
}
-----------------------------------

*/
