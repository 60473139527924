import axios from 'axios'
import { setSnackbar } from './snackbar'
import { push, replace } from '../../helpers/immutable'

const initialState = {
  organisation: null,
  authorizedPerson: null,
  isOpen: false,
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingRemove: false
}

/* TOGGLE_MODAL Action */
const TOGGLE_MODAL = 'organisation/TOGGLE_MODAL'
export const toggle = () => ({
  type: TOGGLE_MODAL
})

const HANDLE_CHANGE = 'organisation/HANDLE_CHANGE'
export const handleChange = authorizedPerson => ({
  type: HANDLE_CHANGE,
  payload: authorizedPerson
})

/* FETCH_AUTHORIZED_PERSONS Action */
const FETCH_AUTHORIZED_PERSONS_START = 'FETCH_AUTHORIZED_PERSONS_START'
const fetchAuthorizedPersonsStart = () => ({
  type: FETCH_AUTHORIZED_PERSONS_START
})

const FETCH_AUTHORIZED_PERSONS_FAIL = 'FETCH_AUTHORIZED_PERSONS_FAIL'
const fetchAuthorizedPersonsFail = err => ({
  type: FETCH_AUTHORIZED_PERSONS_FAIL,
  payload: err
})

const FETCH_AUTHORIZED_PERSONS_SUCCESS = 'FETCH_AUTHORIZED_PERSONS_SUCCESS'
const fetchAuthorizedPersonsSuccess = list => ({
  type: FETCH_AUTHORIZED_PERSONS_SUCCESS,
  payload: list
})

export const fetchAll = id => async dispatch => {
  dispatch(fetchAuthorizedPersonsStart())
  try {
    const response = await axios.get(
      `/v1/organisations/${id}?authorizedPersons=true`
    )
    dispatch(fetchAuthorizedPersonsSuccess(response.data[0]))
  } catch (err) {
    dispatch(fetchAuthorizedPersonsFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REMOVE_AUTHORIZED_PERSON_START */
const REMOVE_AUTHORIZED_PERSON_START = 'REMOVE_AUTHORIZED_PERSON_START'
const removeAuthorizedPersonStart = () => ({
  type: REMOVE_AUTHORIZED_PERSON_START
})

const REMOVE_AUTHORIZED_PERSON_FAIL = 'REMOVE_AUTHORIZED_PERSON_FAIL'
const removeAuthorizedPersonFail = err => ({
  type: REMOVE_AUTHORIZED_PERSON_FAIL,
  payload: err
})

const REMOVE_AUTHORIZED_PERSON_SUCCESS = 'REMOVE_AUTHORIZED_PERSON_SUCCESS'
const removeAuthorizedPersonSuccess = authorizedPerson => ({
  type: REMOVE_AUTHORIZED_PERSON_SUCCESS,
  payload: authorizedPerson
})

export const remove = authorizedPerson => async dispatch => {
  dispatch(removeAuthorizedPersonStart())
  try {
    await axios.delete(
      `/v1/organisations/authorized-persons/${authorizedPerson.id}`
    )
    dispatch(removeAuthorizedPersonSuccess(authorizedPerson.id))
    dispatch(
      setSnackbar(
        `${authorizedPerson.name} har tagits bort som behörig person.`,
        'success'
      )
    )
  } catch (err) {
    dispatch(removeAuthorizedPersonFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* CREATE_AUTHORIZED_PERSON Action */
const CREATE_AUTHORIZED_PERSON_START = 'CREATE_AUTHORIZED_PERSON_START'
const createAuthorizedPersonStart = () => ({
  type: CREATE_AUTHORIZED_PERSON_START
})

const CREATE_AUTHORIZED_PERSON_FAIL = 'CREATE_AUTHORIZED_PERSON_FAIL'
const createAuthorizedPersonFail = err => ({
  type: CREATE_AUTHORIZED_PERSON_FAIL,
  payload: err
})

const CREATE_AUTHORIZED_PERSON_SUCCESS = 'CREATE_AUTHORIZED_PERSON_SUCCESS'
const createAuthorizedPersonSuccess = authorizedPerson => ({
  type: CREATE_AUTHORIZED_PERSON_SUCCESS,
  payload: authorizedPerson
})

export const create = body => async dispatch => {
  dispatch(createAuthorizedPersonStart())
  try {
    const response = await axios.post(
      '/v1/organisations/authorized-persons',
      body
    )
    dispatch(createAuthorizedPersonSuccess(response.data))
    dispatch(
      setSnackbar(
        `${response.data.name} har lagts till som behörig person.`,
        'success'
      )
    )
  } catch (err) {
    dispatch(createAuthorizedPersonFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const authorizedPersons = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen }
    case HANDLE_CHANGE:
      return { ...state, authorizedPerson: action.payload }
    case FETCH_AUTHORIZED_PERSONS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_AUTHORIZED_PERSONS_FAIL:
      return {
        ...state,
        isLoadingFetch: false,
        error: action.payload
      }
    case FETCH_AUTHORIZED_PERSONS_SUCCESS:
      return {
        ...state,
        isLoadingFetch: false,
        error: null,
        organisation: action.payload
      }
    case CREATE_AUTHORIZED_PERSON_START:
      return { ...state, isLoadingCreate: true }
    case CREATE_AUTHORIZED_PERSON_FAIL:
      return {
        ...state,
        isLoadingCreate: false,
        error: action.payload
      }
    case CREATE_AUTHORIZED_PERSON_SUCCESS: {
      const organisation = { ...state.organisation }
      organisation.authorizedPersons = push(action.payload, [
        ...organisation.authorizedPersons
      ])
      return {
        ...state,
        isLoadingCreate: false,
        error: null,
        organisation,
        authorizedPerson: null,
        isOpen: false
      }
    }
    case REMOVE_AUTHORIZED_PERSON_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_AUTHORIZED_PERSON_FAIL:
      return {
        ...state,
        isLoadingRemove: false,
        error: action.payload
      }
    case REMOVE_AUTHORIZED_PERSON_SUCCESS: {
      const organisation = { ...state.organisation }
      organisation.authorizedPersons = replace({ id: action.payload }, null, [
        ...organisation.authorizedPersons
      ])
      return {
        ...state,
        isLoadingRemove: false,
        error: null,
        organisation,
        authorizedPerson: null
      }
    }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default authorizedPersons
