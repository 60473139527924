import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import loginReducer from './reducers/login'
import layoutReducer from './reducers/layout'
import adminReducer from './reducers/admin'
import userListReducer from './reducers/users/list'
import userDetailReducer from './reducers/users/detail'
import realEstatesListReducer from './reducers/realestates/list'
import realEstateDetailReducer from './reducers/realestates/detail'
import blueprintReducer from './reducers/blueprint'
import registerReducer from './reducers/register'
import recoverReducer from './reducers/recover'
import snackbarReducer from './reducers/snackbar'
import organisationReducer from './reducers/organisation'
import { notificationsSlice } from './reducers/notificationsSlice'

/* Middlewares */
const loggerMiddleware = store => next => action => {
  console.log('Action type:', action.type)
  console.log('Action payload:', action.payload)
  console.log('State before:', store.getState())
  next(action)
  console.log('State after:', store.getState())
}

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    notifications: notificationsSlice.reducer,
    login: loginReducer,
    layout: layoutReducer,
    realEstateList: realEstatesListReducer,
    realEstateDetail: realEstateDetailReducer,
    blueprintDetail: blueprintReducer,
    admin: adminReducer,
    organisation: organisationReducer,
    userList: userListReducer,
    userDetail: userDetailReducer,
    register: registerReducer,
    recover: recoverReducer,
    snackbar: snackbarReducer,
    router: connectRouter(history)
  },
  middleware: [thunkMiddleware, routerMiddleware(history)]
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
