import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import withUser from 'src/utils/withUser'
import { Country } from 'src/utils/constants'
import Theme from '../helpers/Theme'

const CountryConstants = Country

/* eslint-disable */
const NorwegianSSN = require('src/utils/ssnNorwegian')

const styles = {
  root: {
    display: 'inline-block',
    fontFamily: '"Open sans", sans-serif',
    fontWeight: 600,
    boxSizing: 'border-box',
    color: '#1c1c1c',
    border: 'none',
    borderRadius: 3,
    minHeight: 48,
    minWidth: props => (props.minWidth ? 140 : undefined),
    paddingRight: '1.125rem',
    paddingLeft: '1.125rem',
    textTransform: 'uppercase',
    fontSize: 16,
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
    '&:disabled': {
      pointerEvents: 'none',
      cursor: 'default'
      // color: '#aaa'
    }
  },
  primary: {
    border: '1px solid rgba(0,0,0,0.0)',
    backgroundColor: Theme.Colors.PRIMARY_TONE_1,
    color: Theme.Colors.TEXT,
    '&:disabled': {
      color: Theme.Colors.MATERIAL_GREY,
      backgroundColor: Theme.Colors.MIDDLE_GREY
    },
    '&:hover:not(:disabled)': {
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)',
      backgroundColor: Theme.Colors.PRIMARY
    },
    '&:active': {
      backgroundColor: Theme.Colors.PRIMARY,
      border: `1px solid ${Theme.Colors.TEXT}`,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: Theme.Colors.PRIMARY,
      border: `1px solid ${Theme.Colors.TEXT}`,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    }
  },
  secondary: {
    backgroundColor: Theme.Colors.LOGO_BLACK,
    color: Theme.Colors.WHITE,
    '&:disabled': {
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
      backgroundColor: Theme.Colors.GREY
    },
    '&:hover:not(:disabled)': {
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)',
      backgroundColor: Theme.Colors.DARK_GREY
    },
    '&:active': {
      backgroundColor: Theme.Colors.LOGO_BLACK,
      color: Theme.Colors.PRIMARY_TONE_2,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: Theme.Colors.LOGO_BLACK,
      color: Theme.Colors.PRIMARY_TONE_2,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    }
  },
  outlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${Theme.Colors.TEXT}`,
    color: Theme.Colors.TEXT,
    boxShadow: 'none',
    '&:disabled': {
      borderColor: Theme.Colors.GREY,
      color: Theme.Colors.MIDDLE_GREY
    },
    '&:hover:not(:disabled)': {
      backgroundColor: Theme.Colors.LIGHT_GREY
    },
    '&:active': {
      backgroundColor: Theme.Colors.LIGHT_GREY,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: Theme.Colors.LIGHT_GREY,
      boxShadow:
        '0 3px 3px -2px rgba(0,0,0,0.3), 0 3px 4px 0 rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.2)!important',
      outline: 'none'
    }
  },
  none: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    textTransform: 'none',
    minWidth: 'auto',
    '&:disabled': {
      color: Theme.Colors.MIDDLE_GREY
    },
    '&:hover:not(:disabled)': {
      backgroundColor: Theme.Colors.LIGHT_GREY
    },
    '&:active': {
      backgroundColor: 'var(--color-primary-tone-3)',
      boxShadow: 'none!important',
      outline: 'none!important'
    },
    '&:focus': {
      backgroundColor: 'transparent',
      boxShadow: 'none!important',
      outline: 'none!important'
    }
  },
  noHover: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    textTransform: 'none',
    minWidth: 'auto',
    padding: '10px 20px',
    '&:hover': {
      color: 'var(--color-primary-tone-2)'
    },
    '&:disabled': {
      color: Theme.Colors.MIDDLE_GREY
    }
  },
  iconRoot: {
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 5,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 500,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: props =>
        props.darkBg ? 'var(--color-white)' : 'var(--color-primary-tone-3)'
    }
  },
  icon: {
    fontSize: 24
  },
  link: {
    minHeight: 'auto',
    minWidth: 'auto',
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'var(--color-button-link)',
    textTransform: 'none',
    fontSize: 14,
    padding: 0
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 56,
    backgroundColor: 'var(--color-primary)',
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.12), 0 6px 6px 0 rgba(0,0,0,0.24)',
    borderRadius: 500
  },
  bankid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 14px 10px 20px',
    width: '100%',
    whiteSpace: 'nowrap'
  },

  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    root: {
      width: '100%'
    },
    circle: {
      width: 56
    }
  }
}

const Button = props => {
  const {
    classes,
    variant,
    loading,
    icon,
    background,
    size,
    darkBg,
    user,
    country,
    dispatch,
    minWidth,
    ...otherProps
  } = props

  otherProps.disabled = otherProps.disabled
  const rootClasses = classnames(classes.root, {
    [classes.primary]: !variant || variant === 'primary',
    [classes.secondary]: variant === 'secondary',
    [classes.outlined]: variant === 'outlined',
    [classes.none]: variant === 'none',
    [classes.noHover]: variant === 'noHover',
    [classes.iconRoot]: variant === 'icon',
    [classes.link]: variant === 'link',
    [classes.circle]: variant === 'circle',
    [classes.bankid]: variant === 'bankid'
  })

  if (loading) {
    otherProps.children = (
      <CircularProgress size={18} thickness={4} style={{ color: '#1c1c1c' }} />
    )
  }

  if (variant === 'icon') {
    return (
      <button type='button' className={rootClasses} {...otherProps}>
        <Icon
          className={classes.icon}
          style={{ fontSize: size, backgroundColor: background }}
        >
          {icon}
        </Icon>
      </button>
    )
  }

  if (variant === 'bankid') {
    const isValidNoSnn =
      user &&
      user.personalNumber &&
      NorwegianSSN.validateNorwegianIdNumber(user.personalNumber)

    return (
      <button
        type='button'
        className={`${classes.root} ${classes.secondary} ${classes.bankid}`}
        {...otherProps}
      >
        {props.children}
        {isValidNoSnn || (country && country === CountryConstants.NO) ? (
          <img
            height={10}
            src='/logos/bankid-no-logo-white.svg'
            style={{ marginLeft: 13 }}
          />
        ) : (
          <img
            height={35}
            src='/logos/bankid-white.png'
            style={{ marginLeft: 15 }}
          />
        )}
      </button>
    )
  }

  if (variant === 'circle') {
    return (
      <button type='button' className={rootClasses} {...otherProps}>
        <Icon className={classes.icon}>add</Icon>
      </button>
    )
  }

  return <button type='button' className={rootClasses} {...otherProps} />
}

Button.propTypes = {
  background: PropTypes.string,
  darkBg: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.number
}

Button.defaultProps = {
  background: 'transparent',
  darkBg: false,
  variant: 'none',
  loading: false,
  icon: null,
  size: 24
}

export default withUser(injectSheet(styles)(Button))
