import axios from 'axios'
import { push } from 'connected-react-router'
import { setSnackbar } from './snackbar'

const initialState = {
  user: {},
  backgroundColor: '#9fa1a3',
  pwStrength: 0,
  readOnly: false,
  isLoadingRecover: false,
  error: null
}

/* HANDLE_CHANGE Action */
const HANDLE_CHANGE = 'recover/HANDLE_CHANGE'
export const handleChange = user => ({
  type: HANDLE_CHANGE,
  payload: user
})

/* HANDLE_SAFETY_LEVEL Action */
const HANDLE_SAFETY_LEVEL = 'recover/HANDLE_SAFETY_LEVEL'
export const handleSafetyLevel = (backgroundColor, pwStrength) => ({
  type: HANDLE_SAFETY_LEVEL,
  payload: { backgroundColor, pwStrength }
})

/* RECOVER Action */
const RECOVER_START = 'RECOVER_START'
const recoverStart = () => ({
  type: RECOVER_START
})

const RECOVER_FAIL = 'RECOVER_FAIL'
const recoverFail = err => ({
  type: RECOVER_FAIL,
  payload: err
})

const RECOVER_SUCCESS = 'RECOVER_SUCCESS'
const recoverSuccess = () => ({
  type: RECOVER_SUCCESS
})

export const recover = (password, token) => async dispatch => {
  dispatch(recoverStart())
  try {
    await axios.post('/v1/users/recover/password', { password, token })
    dispatch(recoverSuccess())
  } catch (err) {
    dispatch(recoverFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
  dispatch(push('/login'))
}

const Recover = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return { ...state, user: action.payload }
    case HANDLE_SAFETY_LEVEL: {
      const { backgroundColor, pwStrength } = action.payload
      return { ...state, backgroundColor, pwStrength }
    }
    case RECOVER_START:
      return { ...state, isLoadingRecover: true }
    case RECOVER_FAIL:
      return { ...state, isLoadingRecover: false, error: action.payload }
    case RECOVER_SUCCESS:
      return {
        ...state,
        isLoadingRecover: false,
        error: null,
        user: {},
        readOnly: true
      }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default Recover
