import axios from 'axios'
import { push } from 'connected-react-router'
import { setSnackbar } from './snackbar'
import { setAuthenticationData } from './login'

const initialState = {
  isFeedbackModalOpen: false,
  isSidebarOpen: false,
  isUserBarOpen: null,
  isLoadingCreate: false,
  isLoadingImpersonate: false,
  isLoadingRemove: false,
  error: null,
  showDeleteConfirmation: false,
  deletePayload: null
}

/* TOGGLE_DELETE_MODAL */
const TOGGLE_MODAL = 'layout/TOGGLE_MODAL'
export const toggleDeleteConfirmation = ({
  onConfirm,
  requiredInput,
  content,
  deleteConfirmationObject
}) => ({
  type: TOGGLE_MODAL,
  payload: {
    onConfirm,
    requiredInput,
    content,
    deleteConfirmationObject
  }
})

/* HANDLE_DELETE_INPUT Action */
const HANDLE_DELETE_INPUT = 'HANDLE_DELETE_INPUT'
export const handleDeleteInput = input => ({
  type: HANDLE_DELETE_INPUT,
  payload: input
})

/* CALL_REMOVE Action */
const REMOVE_ENTITY_START = 'REMOVE_ENTITY_START'
const removeEntityStart = () => ({
  type: REMOVE_ENTITY_START
})

const REMOVE_ENTITY_FAIL = 'REMOVE_ENTITY_FAIL'
const removeEntityFail = err => ({
  type: REMOVE_ENTITY_FAIL,
  payload: err
})

const REMOVE_ENTITY_SUCCESS = 'REMOVE_ENTITY_SUCCESS'
const removeEntitySuccess = () => ({
  type: REMOVE_ENTITY_SUCCESS
})

export const remove = () => async (dispatch, getState) => {
  const { deletePayload } = getState().layout
  dispatch(removeEntityStart())
  try {
    await deletePayload.onConfirm(deletePayload.deleteConfirmationObject)
    dispatch(removeEntitySuccess())
  } catch (err) {
    dispatch(removeEntityFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* HANDLE_SIGN_OUT Action */
const HANDLE_SIGN_OUT = 'HANDLE_SIGN_OUT'
export const handleSignOut = () => ({
  type: HANDLE_SIGN_OUT
})

/* TOGGLE_USER_BAR Action */
const TOGGLE_USER_BAR = 'TOGGLE_USER_BAR'
export const toggleUserBar = target => ({
  type: TOGGLE_USER_BAR,
  payload: target
})

/* TOGGLE_DRAWER Action */
const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER
})

/* TOGGLE_FEEDBACK_MODAL Action */
const TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL'
export const toggleFeedbackModal = () => ({
  type: TOGGLE_FEEDBACK_MODAL
})

/* CREATE_FEEDBACK Action */

const CREATE_FEEDBACK_START = 'CREATE_FEEDBACK_START'
const createFeedbackStart = () => ({
  type: CREATE_FEEDBACK_START
})

const CREATE_FEEDBACK_FAIL = 'CREATE_FEEDBACK_FAIL'
const createFeedbackFail = err => ({
  type: CREATE_FEEDBACK_FAIL,
  payload: err
})

const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS'
// Data contains feedback string
const createFeedbackSuccess = () => ({
  type: CREATE_FEEDBACK_SUCCESS
})

export const handleCreateFeedback = feedback => async dispatch => {
  dispatch(createFeedbackStart())
  try {
    await axios.post('/v1/feedback', { content: feedback })
    dispatch(createFeedbackSuccess())
    dispatch(setSnackbar('Tack för din feedback!', 'success'))
  } catch (err) {
    dispatch(createFeedbackFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REVERSE_IMPERSONATE Action */

const REVERSE_IMPERSONATE_START = 'layout/REVERSE_IMPERSONATE_START'
const reverseImpersonateStart = () => ({
  type: REVERSE_IMPERSONATE_START
})

const REVERSE_IMPERSONATE_FAIL = 'layout/REVERSE_IMPERSONATE_FAIL'
const reverseImpersonateFail = error => ({
  type: REVERSE_IMPERSONATE_FAIL,
  payload: error
})

const REVERSE_IMPERSONATE_SUCCESS = 'layout/REVERSE_IMPERSONATE_SUCCESS'
// Data contains Token, user and expires
const reverseImpersonateSuccess = data => ({
  type: REVERSE_IMPERSONATE_SUCCESS,
  payload: data
})

export const handleReverseImpersonate = () => async dispatch => {
  dispatch(reverseImpersonateStart())
  try {
    const response = await axios.get('/v1/impersonate/reverse')
    dispatch(reverseImpersonateSuccess(response.data))
    dispatch({ type: 'CLEAR_STATE' })
    dispatch(setAuthenticationData(response.data))
  } catch (err) {
    dispatch(reverseImpersonateFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
  dispatch(push('/dashboard'))
}

const layout = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        showDeleteConfirmation: !state.showDeleteConfirmation,
        deletePayload: action.payload
      }
    case TOGGLE_FEEDBACK_MODAL:
      return { ...state, isFeedbackModalOpen: !state.isFeedbackModalOpen }
    case TOGGLE_DRAWER:
      return { ...state, isSidebarOpen: !state.isSidebarOpen }
    case TOGGLE_USER_BAR:
      return { ...state, isUserBarOpen: action.payload }
    case CREATE_FEEDBACK_START:
      return { ...state, isLoadingCreate: true }
    case CREATE_FEEDBACK_FAIL: {
      return {
        ...state,
        isLoadingCreate: false,
        error: action.payload
      }
    }
    case CREATE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        isLoadingCreate: false,
        feedback: '',
        isFeedbackModalOpen: false,
        error: null
      }
    }
    case REVERSE_IMPERSONATE_START:
      return { ...state, isLoadingImpersonate: true }
    case REVERSE_IMPERSONATE_FAIL: {
      return {
        ...state,
        isLoadingImpersonate: false,
        error: action.payload
      }
    }
    case REVERSE_IMPERSONATE_SUCCESS: {
      const { token, user, expires } = action.payload
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token)
      localStorage.setItem('expires', expires)
      return {
        ...state,
        isLoadingImpersonate: false,
        isUserBarOpen: null,
        error: null
      }
    }
    case HANDLE_SIGN_OUT: {
      localStorage.clear()
      return { ...state, isUserBarOpen: null }
    }
    case REMOVE_ENTITY_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_ENTITY_FAIL:
      return { ...state, isLoadingRemove: false, error: action.payload }
    case REMOVE_ENTITY_SUCCESS:
      return {
        ...state,
        isLoadingRemove: false,
        showDeleteConfirmation: false,
        input: '',
        deletePayload: null
      }
    case HANDLE_DELETE_INPUT:
      return {
        ...state,
        deletePayload: { ...state.deletePayload, input: action.payload }
      }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default layout
