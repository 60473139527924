export const getObjectIdx = (obj, array) => {
  for (let i = 0; i < array.length; i += 1) {
    if (parseInt(array[i].id, 10) === parseInt(obj.id, 10)) {
      return i
    }
  }
  return -1
}

export const replace = (oldObject, newObject, array) => {
  if (!array) {
    return []
  }
  const idx = getObjectIdx(oldObject, array)
  if (idx === -1) {
    return array
  }
  const newObjects = [...array]
  if (newObject) {
    newObjects.splice(idx, 1, newObject)
  } else {
    newObjects.splice(idx, 1)
  }
  return newObjects
}

export const push = (object, array) => {
  const newArray = [...array]
  newArray.push(object)
  return newArray
}

export const unshift = (object, array) => {
  const newArray = [...array]
  newArray.unshift(object)
  return newArray
}
