import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  root: {
    fontFamily: '"Open Sans", sans-serif',
    margin: 0,
    padding: 0,
    color: '#1c1c1c'
  },
  h1: {
    fontFamily: 'BreeSerif-Bold',
    lineHeight: 1.5,
    fontSize: 28
  },
  h2: {
    fontFamily: 'BreeSerif-Bold',
    lineHeight: 1.5,
    fontSize: 18
  },
  h3: {
    lineHeight: 1.5,
    fontSize: 18,
    fontWeight: 300
  },
  h4: {
    fontSize: 16,
    fontWeight: 200,
    lineHeight: 1.5
  },
  body: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '22px',
    wordBreak: 'break-word'
  },
  body2: {
    fontSize: 14
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '33px',
    wordBreak: 'break-word'
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '33px',
    wordBreak: 'break-word'
  },
  subtitleBig: {
    fontSize: 20,
    lineHeight: 1.35
  },
  subtitleSmall: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    wordBreak: 'break-word'
  },
  bodyItalic: {
    fontSize: 16,
    fontStyle: 'italic',
    lineHeight: '22px'
  },
  bodyFaded: {
    color: '#8E8E8E',
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '26px'
  },
  alertTitle: {
    fontSize: 20,
    letterSpacing: '0.19px',
    lineheight: 1.35,
    fontWeight: 'bold'
  },
  small: {
    fontSize: '0.75rem'
  },
  leftAlign: {
    textAlign: 'left'
  },
  centerAlign: {
    textAlign: 'center'
  },
  rightAlign: {
    textAlign: 'right'
  },
  lineBreaks: {
    whiteSpace: 'break-spaces'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  normalLineHeight: {
    lineHeight: 1.5
  },
  block: {
    display: 'block',
    width: '100%'
  },
  faded: {
    opacity: 0.5
  },
  dark: {
    color: '#565656'
  },
  italic: {
    fontStyle: 'italic'
  },
  fontNormal: {
    fontWeight: 'normal'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  /*                *
   *                *
   * Mobile styling *
   *                *
   *                */

  '@media (max-width: 900px)': {
    title: {
      fontSize: 20,
      fontWeight: 600
    }
  }
}

const variantElements = {
  body: 'span',
  body2: 'span',
  bodyFaded: 'span',
  italic: 'span',
  title: 'h3',
  subtitle: 'h4',
  alertTitle: 'h4',
  subtitleBig: 'h4'
}

const Typography = props => {
  const {
    classes,
    variant,
    align,
    bold,
    fontNormal,
    ellipsis,
    block,
    faded,
    dark,
    italic,
    normalLineHeight,
    small,
    lineBreaks,
    leftAlign,
    centerAlign,
    rightAlign,
    noWrap,
    ...otherProps
  } = props

  const element = variantElements[variant] || 'h5'
  const className = variant

  const alignmentClass = `${align}Align`
  const rootClasses = classnames(
    classes.root,
    classes[className],
    classes[alignmentClass],
    {
      [classes.ellipsis]: ellipsis,
      [classes.lineBreaks]: lineBreaks,
      [classes.block]: block,
      [classes.faded]: faded,
      [classes.dark]: dark,
      [classes.italic]: italic,
      [classes.small]: small,
      [classes.fontNormal]: fontNormal,
      [classes.normalLineHeight]: normalLineHeight,
      [classes.leftAlign]: leftAlign,
      [classes.centerAlign]: centerAlign,
      [classes.rightAlign]: rightAlign,
      [classes.noWrap]: noWrap
    }
  )

  const style = Object.assign({}, otherProps.style)
  if (bold) {
    style.fontWeight = bold >= 100 ? bold : 600
  }
  const Component = React.createElement(element, {
    className: rootClasses,
    onClick: () => {},
    ...otherProps,
    title:
      ellipsis && typeof otherProps.children === 'string'
        ? otherProps.children
        : undefined,
    style
  })

  return Component
}

Typography.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'title',
    'body',
    'body2',
    'bodyFaded',
    'subtitle',
    'subtitleBig',
    'subtitleSmall',
    'alertTitle',
    'small',
    'italic'
  ]),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  ellipsis: PropTypes.bool,
  lineBreaks: PropTypes.bool,
  block: PropTypes.bool,
  faded: PropTypes.bool,
  dark: PropTypes.bool,
  normalLineHeight: PropTypes.bool,
  italic: PropTypes.bool,
  small: PropTypes.bool,
  bold: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  fontNormal: PropTypes.bool,
  noWrap: PropTypes.bool
}

Typography.defaultProps = {
  variant: 'body',
  align: 'left',
  ellipsis: false,
  lineBreaks: false,
  block: false,
  faded: false,
  dark: false,
  normalLineHeight: false,
  italic: false,
  small: false,
  bold: false,
  fontNormal: false,
  noWrap: false
}

export default injectSheet(styles)(Typography)
