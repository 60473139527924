export default {
  Colors: {
    PRIMARY: '#2cd5c4',
    LOGO_BLACK: '#1b1c1a',
    TEXT: '#1c1c1c',
    BUTTON_LINK: '#2CD5C4',
    PRIMARY_TONE: '#def6f4',
    PRIMARY_TONE_1: '#6BE1D5',
    PRIMARY_TONE_2: '#2CD5C4',
    PRIMARY_TONE_3: '#b7e1dd',
    PRIMARY_TONE_4: '#D1D3D4',
    WHITE: '#fff',
    BLACK: '#1c1c1c',
    DIVIDER_LIGHT: 'rgba(0, 0, 0, 0.12)',
    ERROR: 'rgb(215, 75, 75)',
    GREY: '#bebebe',
    DARK_GREY: '#3d3e3c',
    LIGHT_GREY: '#e8e8e8',
    LIGHT_GREY_2: '#d8d8d8',
    ULTRA_LIGHT_GREY: '#f8f8f8',
    MIDDLE_GREY: '#8e8e8e',
    MATERIAL_GREY: '#f0f0f0'
  },
  FontFamily: {
    body: '"Open Sans", sans-serif'
  },
  Typography: {
    body: {
      fontSize: '16px !important',
      letterSpacing: '0.15px !important',
      lineHeight: '22px !important',
      wordBreak: 'break-word',
      fontFamily: '"Open Sans", sans-serif !important'
    },
    inputs: {
      fontSize: '16px !important',
      letterSpacing: '0 !important',
      lineHeight: '24px !important',
      fontFamily: '"Open Sans", sans-serif !important'
    }
  }
}
