import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IServiceRequest } from 'src/pages/Apartments/ServiceRequests/types'

export enum NotificationType {
  ServiceRequest = 'ServiceRequest'
}

type RemoveNotificationPayload = PayloadAction<{
  notification: any
  type: NotificationType
}>

interface NotificationsState {
  serviceRequests: {
    items: IServiceRequest[]
    error: string | null
  }
}

const initialState: NotificationsState = {
  serviceRequests: {
    items: [],
    error: null
  }
}
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationsState>) => {
      return action.payload
    },

    removeNotification: (state, action: RemoveNotificationPayload) => {
      const { notification, type } = action.payload

      if (type === NotificationType.ServiceRequest) {
        const { items } = state.serviceRequests
        const index = items.findIndex(item => item.id === notification?.id)
        if (index !== -1) {
          items.splice(index, 1)
        }
        state.serviceRequests.items = items
      }
    }
  }
})
