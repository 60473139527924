if (!window.env || typeof window.env !== 'object') {
  window.env = {}
}

const { hostname } = window.location
if (/^\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}$/.test(hostname)) {
  window.env.API_BASE_URL = `http://${hostname}:3002`
} else if (hostname === 'localhost') {
  window.env.API_BASE_URL = 'http://localhost:3002'
} else {
  window.env.API_BASE_URL = window.location.origin.replace('portal', 'api')
}
