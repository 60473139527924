import axios from 'axios'
import { stringify } from 'qs'
import { replace } from '../../../helpers/immutable'
import { setSnackbar } from '../snackbar'

const initialState = {
  list: [],
  organisations: [],
  realEstate: null,
  area: null,
  areas: [],
  filter: {
    orderBy: null,
    order: 'asc'
  },
  isOpen: false,
  isOpenArea: false,
  isLoadingAreasFetch: false,
  isLoadingFetch: false,
  isLoadingOrganisationsFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingRemove: false
}

/* TOGGLE REALESTATE MODAL Action */
const TOGGLE_MODAL = 'realEstate/TOGGLE_MODAL'
export const toggle = realEstate => ({
  type: TOGGLE_MODAL,
  payload: realEstate
})

/* HANDLE_CHANGE Actions */
const HANDLE_CHANGE = 'realEstates/HANDLE_CHANGE'
export const handleChange = realEstate => ({
  type: HANDLE_CHANGE,
  payload: realEstate
})

const HANDLE_CHANGE_AREA = 'realEstates/HANDLE_CHANGE_AREA'
export const handleChangeArea = area => ({
  type: HANDLE_CHANGE_AREA,
  payload: area
})

/* HANDLE_FILTER Action */
const HANDLE_FILTER = 'realEstates/HANDLE_FILTER'
const handleFilter = filter => ({
  type: HANDLE_FILTER,
  payload: filter
})

/* FETCH_ALL Action */
const FETCH_ALL_START = 'realEstates/FETCH_ALL_START'
const fetchAllStart = () => ({
  type: FETCH_ALL_START
})
const FETCH_ALL_FAIL = 'realEstates/FETCH_ALL_FAIL'
const fetchAllFail = err => ({
  type: FETCH_ALL_FAIL,
  payload: err
})
const FETCH_ALL_SUCCESS = 'realEstates/FETCH_ALL_SUCCESS'
const fetchAllSuccess = list => ({
  type: FETCH_ALL_SUCCESS,
  payload: list
})

export const fetchAll = newFilter => async (dispatch, getState) => {
  const { filter } = getState().userList
  let sort = stringify(filter)
  if (newFilter) {
    sort = stringify(newFilter)
    dispatch(handleFilter(newFilter))
  }
  const url = `/v1/real-estates?${sort}`
  dispatch(fetchAllStart())
  try {
    const response = await axios.get(url)
    dispatch(fetchAllSuccess(response.data))
  } catch (err) {
    dispatch(fetchAllFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* CREATE_REALESTATE Action */
const CREATE_REALESTATE_START = 'CREATE_REALESTATE_START'
const createRealEstateStart = () => ({
  type: CREATE_REALESTATE_START
})

const CREATE_REALESTATE_FAIL = 'CREATE_REALESTATE_FAIL'
const createRealEstateFail = err => ({
  type: CREATE_REALESTATE_FAIL,
  payload: err
})

const CREATE_REALESTATE_SUCCESS = 'CREATE_REALESTATE_SUCCESS'
const createRealEstateSuccess = body => ({
  type: CREATE_REALESTATE_SUCCESS,
  payload: body
})

export const create = body => async dispatch => {
  dispatch(createRealEstateStart())
  try {
    const response = await axios.post('/v1/real-estates', body)
    dispatch(createRealEstateSuccess(response.data))
    dispatch(
      setSnackbar(`Fastighet - ${response.data.name} har skapats.`, 'success')
    )
  } catch (err) {
    dispatch(createRealEstateFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* UPDATE_REALESTATES Action */
const UPDATE_REALESTATE_START = 'UPDATE_REALESTATE_START'
const updateRealEstateStart = () => ({
  type: UPDATE_REALESTATE_START
})

const UPDATE_REALESTATE_FAIL = 'UPDATE_REALESTATE_FAIL'
const updateRealEstateFail = err => ({
  type: UPDATE_REALESTATE_FAIL,
  payload: err
})

const UPDATE_REALESTATE_SUCCESS = 'UPDATE_REALESTATE_SUCCESS'
const updateRealEstateSuccess = realEstate => ({
  type: UPDATE_REALESTATE_SUCCESS,
  payload: realEstate
})

export const update = realEstate => async dispatch => {
  dispatch(updateRealEstateStart())
  try {
    const response = await axios.put(
      `/v1/real-estates/${realEstate.id}`,
      realEstate
    )
    dispatch(updateRealEstateSuccess(response.data))
    dispatch(
      setSnackbar(
        `Fastighet - ${response.data.name} har uppdaterats`,
        'success'
      )
    )
  } catch (err) {
    dispatch(updateRealEstateFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REMOVE_REALESTATE Action */
const REMOVE_REALESTATE_START = 'REMOVE_REALESTATE_START'
const removeRealEstateStart = () => ({
  type: REMOVE_REALESTATE_START
})

const REMOVE_REALESTATE_FAIL = 'REMOVE_REALESTATE_FAIL'
const removeRealEstateFail = err => ({
  type: REMOVE_REALESTATE_FAIL,
  payload: err
})

const REMOVE_REALESTATE_SUCCESS = 'REMOVE_REALESTATE_SUCCESS'
const removeRealEstateSuccess = id => ({
  type: REMOVE_REALESTATE_SUCCESS,
  payload: id
})

export const remove = realEstate => async dispatch => {
  dispatch(removeRealEstateStart())
  try {
    await axios.delete(`/v1/real-estates/${realEstate.id}`)
    dispatch(removeRealEstateSuccess(realEstate.id))
    dispatch(
      setSnackbar(`Fastighet - ${realEstate.name} har tagits bort.`, 'success')
    )
  } catch (err) {
    dispatch(removeRealEstateFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* FETCH_AREAS Action */
const FETCH_AREAS_START = 'realEstates/FETCH_AREAS_START'
const fetchAreasStart = () => ({
  type: FETCH_AREAS_START
})

const FETCH_AREAS_FAIL = 'realEstates/FETCH_AREAS_FAIL'
const fetchAreasFail = err => ({
  type: FETCH_AREAS_FAIL,
  payload: err
})

const FETCH_AREAS_SUCCESS = 'realEstates/FETCH_AREAS_SUCCESS'
const fetchAreasSuccess = areas => ({
  type: FETCH_AREAS_SUCCESS,
  payload: areas
})

export const fetchAreas = () => async dispatch => {
  dispatch(fetchAreasStart())
  try {
    const response = await axios.get('/v1/areas')
    dispatch(fetchAreasSuccess(response.data))
  } catch (err) {
    dispatch(fetchAreasFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* TOGGLE AREA MODAL Action */
const TOGGLE_AREA_MODAL = 'realEstate/TOGGLE_AREA_MODAL'
export const toggleArea = () => ({
  type: TOGGLE_AREA_MODAL
})

/* CREATE_AREA Action */
const CREATE_AREA_START = 'CREATE_AREA_START'
const createAreaStart = () => ({
  type: CREATE_AREA_START
})

const CREATE_AREA_FAIL = 'CREATE_AREA_FAIL'
const createAreaFail = err => ({
  type: CREATE_AREA_FAIL,
  payload: err
})

const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS'
const createAreaSuccess = body => ({
  type: CREATE_AREA_SUCCESS,
  payload: body
})

export const createArea = body => async dispatch => {
  dispatch(createAreaStart())
  try {
    const response = await axios.post('/v1/areas', body)
    dispatch(createAreaSuccess(response.data))
    dispatch(
      setSnackbar(`Område - ${response.data.name} har skapats`, 'success')
    )
  } catch (err) {
    dispatch(createAreaFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* FETCH_ORGANISATIONS Action */
const FETCH_ORGANISATIONS_START = 'realEstates/FETCH_ORGANISATIONS_START'
const fetchOrganisationsStart = () => ({
  type: FETCH_ORGANISATIONS_START
})

const FETCH_ORGANISATIONS_FAIL = 'realEstates/FETCH_ORGANISATIONS_FAIL'
const fetchOrganisationsFail = err => ({
  type: FETCH_ORGANISATIONS_FAIL,
  payload: err
})

const FETCH_ORGANISATIONS_SUCCESS = 'realEstates/FETCH_ORGANISATIONS_SUCCESS'
const fetchOrganisationsSuccess = organisations => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload: organisations
})

export const fetchOrganisations = () => async dispatch => {
  dispatch(fetchOrganisationsStart())
  try {
    const response = await axios.get(
      '/v1/organisations/?&orderBy=name&order=asc'
    )
    dispatch(fetchOrganisationsSuccess(response.data))
  } catch (err) {
    dispatch(fetchOrganisationsFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const realEstates = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return { ...state, realEstate: action.payload }
    case HANDLE_CHANGE_AREA:
      return { ...state, area: action.payload }
    case HANDLE_FILTER:
      return { ...state, filter: action.payload }
    case TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen, realEstate: action.payload }
    case TOGGLE_AREA_MODAL: {
      if (state.isOpenArea) return { ...state, isOpenArea: false, area: null }
      return { ...state, isOpenArea: true, area: {} }
    }
    case FETCH_ALL_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ALL_FAIL:
      return { ...state, isLoadingFetch: false, error: action.payload }
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isLoadingFetch: false,
        list: action.payload,
        error: null
      }
    case CREATE_REALESTATE_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_REALESTATE_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload,
        isOpen: false
      }
    case CREATE_REALESTATE_SUCCESS: {
      const list = [...state.list]
      list.push(action.payload)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        realEstate: null,
        error: null,
        isOpen: false,
        isOpenArea: false
      }
    }
    case UPDATE_REALESTATE_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_REALESTATE_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case UPDATE_REALESTATE_SUCCESS: {
      const list = replace({ id: action.payload.id }, action.payload, [
        ...state.list
      ])
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        realEstate: null,
        error: null,
        isOpen: false,
        isOpenArea: false
      }
    }
    case REMOVE_REALESTATE_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_REALESTATE_FAIL:
      return { ...state, isLoadingRemove: false, error: action.payload }
    case REMOVE_REALESTATE_SUCCESS: {
      const list = replace({ id: action.payload }, null, [...state.list])
      return {
        ...state,
        isLoadingRemove: false,
        list,
        error: null
      }
    }
    case FETCH_AREAS_START:
      return { ...state, isLoadingAreasFetch: true }
    case FETCH_AREAS_FAIL:
      return { ...state, isLoadingAreasFetch: false, error: action.payload }
    case FETCH_AREAS_SUCCESS:
      return {
        ...state,
        isLoadingAreasFetch: false,
        areas: action.payload,
        error: null
      }
    case CREATE_AREA_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_AREA_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case CREATE_AREA_SUCCESS: {
      const areas = [...state.areas]
      areas.push(action.payload)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: null,
        areas,
        isOpenArea: false
      }
    }
    case FETCH_ORGANISATIONS_START:
      return { ...state, isLoadingOrganisationsFetch: true }
    case FETCH_ORGANISATIONS_FAIL:
      return {
        ...state,
        isLoadingOrganisationsFetch: false,
        error: action.payload
      }
    case FETCH_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        isLoadingOrganisationsFetch: false,
        organisations: action.payload,
        error: null
      }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default realEstates
