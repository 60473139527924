import axios from 'axios'
import { replace, push } from '../../helpers/immutable'
import { setSnackbar } from './snackbar'

const initialState = {
  blueprint: null,
  pins: [],
  activePin: { item: {} },
  accessRoles: [],
  showSummary: false,
  collapsedPin: null,
  pinClicked: false,
  pinType: 'Cylinder',
  isDraggingEnabled: false,
  canCreateBlueprintItem: true,
  isLoadingFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingUpload: false,
  isOpen: false,
  error: null
}

export const clearState = () => ({
  type: 'CLEAR_STATE'
})

/* ALL_PIN_ACTIONS */
const HANDLE_MOUSE_DOWN = 'blueprint/HANDLE_MOUSE_DOWN'
export const handleMouseDown = pin => ({
  type: HANDLE_MOUSE_DOWN,
  payload: pin
})

const HANDLE_MOUSE_MOVE = 'blueprint/HANDLE_MOUSE_MOVE'
export const handleMouseMove = pin => ({
  type: HANDLE_MOUSE_MOVE,
  payload: pin
})

const HANDLE_MOUSE_UP = 'blueprint/HANDLE_MOUSE_UP'
export const handleMouseUp = pin => ({
  type: HANDLE_MOUSE_UP,
  payload: pin
})

/* BLUEPRINT_ACTIONS */

const CLEAR_BLUEPRINT = 'blueprintDetail/CLEAR_BLUEPRINT'
export const clearBlueprint = () => ({
  type: CLEAR_BLUEPRINT
})

const FETCH_BLUEPRINT_START = 'blueprintDetail/FETCH_BLUEPRINT_START'
const fetchBlueprintStart = () => ({
  type: FETCH_BLUEPRINT_START
})

const FETCH_BLUEPRINT_FAIL = 'blueprintDetail/FETCH_BLUEPRINT_FAIL'
const fetchBlueprintFail = err => ({
  type: FETCH_BLUEPRINT_FAIL,
  payload: err
})

const FETCH_BLUEPRINT_SUCCESS = 'blueprintDetail/FETCH_BLUEPRINT_SUCCESS'
const fetchBlueprintSuccess = blueprint => ({
  type: FETCH_BLUEPRINT_SUCCESS,
  payload: blueprint
})

export const fetch = id => async dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchBlueprintStart())
  try {
    const response = await axios.get(`/v1/blueprints/${id}`)
    const newResponse = { ...response.data, token }
    dispatch(fetchBlueprintSuccess(newResponse))
  } catch (err) {
    dispatch(fetchBlueprintFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* ON_BLUEPRINT_CLICK Action */
const BLUEPRINT_CLICK = 'BLUEPRINT_CLICK'
// Data  contains pins, activePin
export const blueprintClick = data => ({
  type: BLUEPRINT_CLICK,
  payload: data
})

/* ON_PIN_CLICK Action */
const PIN_CLICK = 'PIN_CLICK'
// Data  contains pins, activePin
export const pinClick = data => ({
  type: PIN_CLICK,
  payload: data
})

/* UPDATE_PIN Action */
const UPDATE_PIN_START = 'UPDATE_PIN_START'
const updatePinStart = () => ({
  type: UPDATE_PIN_START
})

const UPDATE_PIN_FAIL = 'UPDATE_PIN_FAIL'
const updatePinFail = err => ({
  type: UPDATE_PIN_FAIL,
  payload: err
})
const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS'
const updatePinSuccess = pin => ({
  type: UPDATE_PIN_SUCCESS,
  payload: pin
})

export const updatePin = pin => async dispatch => {
  dispatch(updatePinStart())
  try {
    const response = await axios.put(
      `/v1/blueprints/${pin.blueprintId}/items/${pin.id}`,
      pin.item ? pin.item : pin
    )
    dispatch(updatePinSuccess(response.data))
    dispatch(
      setSnackbar(`Markering ${pin.sequence} har uppdaterats.`, 'success')
    )
  } catch (err) {
    dispatch(updatePinFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* CREATE_PIN Action */
const CREATE_PIN_START = 'CREATE_PIN_START'
const createPinStart = () => ({
  type: CREATE_PIN_START
})

const CREATE_PIN_FAIL = 'CREATE_PIN_FAIL'
const createPinFail = err => ({
  type: CREATE_PIN_FAIL,
  payload: err
})
const CREATE_PIN_SUCCESS = 'CREATE_PIN_SUCCESS'
const createPinSuccess = item => ({
  type: CREATE_PIN_SUCCESS,
  payload: {
    item
  }
})

export const createPin = item => async dispatch => {
  dispatch(createPinStart())
  try {
    const { data: newItem } = await axios.post(
      `/v1/blueprints/${item.blueprintId}/items`,
      item
    )
    dispatch(createPinSuccess(newItem))
    dispatch(
      setSnackbar(`Markering ${newItem.sequence} har skapats.`, 'success')
    )
  } catch (err) {
    dispatch(createPinFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* EDIT_PIN Action */
const EDIT_PIN_START = 'EDIT_PIN_START'
const editPinStart = () => ({
  type: EDIT_PIN_START
})

const EDIT_PIN_FAIL = 'EDIT_PIN_FAIL'
const editPinFail = err => ({
  type: EDIT_PIN_FAIL,
  payload: err
})
const EDIT_PIN_SUCCESS = 'EDIT_PIN_SUCCESS'
const editPinSuccess = pin => ({
  type: EDIT_PIN_SUCCESS,
  payload: pin
})

export const editPin = pin => async dispatch => {
  dispatch(editPinStart())
  try {
    const response = await axios.put(
      `/v1/blueprints/${pin.blueprintId}/items/${pin.id}`,
      pin
    )
    dispatch(editPinSuccess(response.data))
    dispatch(
      setSnackbar(
        `Markering ${response.data.sequence} har uppdaterats`,
        'success'
      )
    )
  } catch (err) {
    dispatch(editPinFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* FILE_UPLOAD Action */
const FILE_UPLOAD_START = 'blueprint/FILE_UPLOAD_START'
const fileUploadStart = () => ({
  type: FILE_UPLOAD_START
})

const FILE_UPLOAD_FAIL = 'blueprint/FILE_UPLOAD_FAIL'
const fileUploadFail = err => ({
  type: FILE_UPLOAD_FAIL,
  payload: err
})

const FILE_UPLOAD_SUCCESS = 'blueprint/FILE_UPLOAD_SUCCESS'
const fileUploadSuccess = pin => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: pin
})

export const uploadFile = file => async (dispatch, getState) => {
  const { activePin } = getState().blueprintDetail
  dispatch(fileUploadStart())
  try {
    const response = await axios.post(
      `/v1/blueprints/${activePin.item.blueprintId}/items/${activePin.item.id}/files`,
      { file }
    )
    dispatch(fileUploadSuccess(response.data))
    dispatch(setSnackbar(global._('Common.FileUploadSuccess'), 'success'))
  } catch (err) {
    dispatch(fileUploadFail(err))
    const msg = err.response ? err.response.data : err.message
    dispatch(setSnackbar(global._(msg), 'error'))
  }
}

const HANDLE_PIN_TYPE = 'HANDLE_PIN_TYPE'
export const handlePinType = pinType => ({
  type: HANDLE_PIN_TYPE,
  payload: pinType
})

const HANDLE_ACCESS_ROLES = 'HANDLE_ACCESS_ROLES'
export const handleAccessRoles = accessRoles => ({
  type: HANDLE_ACCESS_ROLES,
  payload: accessRoles
})

const HANDLE_CHANGE = 'blueprint/HANDLE_CHANGE'
export const handleChange = pin => ({
  type: HANDLE_CHANGE,
  payload: pin
})

const TOGGLE_MODAL = 'blueprint/TOGGLE_MODAL'
export const toggle = pins => ({
  type: TOGGLE_MODAL,
  payload: pins
})

const TOGGLE_SUMMARY = 'blueprint/TOGGLE_SUMMARY'
export const toggleSummary = () => ({
  type: TOGGLE_SUMMARY
})

const TOGGLE_COLLAPSE = 'blueprint/TOGGLE_COLLAPSE'
export const toggleCollapse = collapsedPin => ({
  type: TOGGLE_SUMMARY,
  payload: collapsedPin
})

const TOGGLE_DRAGGING = 'blueprint/TOGGLE_DRAGGING'
export const toggleDragging = isDraggingEnabled => ({
  type: TOGGLE_DRAGGING,
  payload: isDraggingEnabled
})
const UPDATE_PINS_LIST = 'UPDATE_PINS_LIST'
export const updatePinsList = pins => ({
  type: UPDATE_PINS_LIST,
  payload: pins
})

const UPDATE_ACTIVE_PIN = 'UPDATE_ACTIVE_PIN'
export const updateActivePin = () => ({
  type: UPDATE_ACTIVE_PIN
})

/* REMOVE_PIN Action */
const REMOVE_PIN_START = 'REMOVE_PIN_START'
const removePinStart = () => ({
  type: REMOVE_PIN_START
})

const REMOVE_PIN_FAIL = 'REMOVE_PIN_FAIL'
const removePinFail = err => ({
  type: REMOVE_PIN_FAIL,
  payload: err
})

const REMOVE_PIN_SUCCESS = 'REMOVE_PIN_SUCCESS'
const removePinSuccess = id => ({
  type: REMOVE_PIN_SUCCESS,
  payload: id
})

export const remove = pin => async dispatch => {
  dispatch(removePinStart())
  try {
    await axios.delete(`/v1/blueprints/${pin.item.blueprintId}/items/${pin.id}`)
    dispatch(removePinSuccess(pin.id))
    dispatch(
      setSnackbar(`Markering ${pin.item.sequence} har tagits bort.`, 'success')
    )
  } catch (err) {
    dispatch(removePinFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const blueprintDetail = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_BLUEPRINT:
      return { ...state, blueprint: null }
    case HANDLE_PIN_TYPE:
      return { ...state, pinType: action.payload }
    case UPDATE_PINS_LIST:
      return { ...state, pins: action.payload }
    case UPDATE_ACTIVE_PIN:
      return { ...state, activePin: { item: { type: 'Cylinder' } } }
    case HANDLE_ACCESS_ROLES:
      return { ...state, accessRoles: action.payload }
    case HANDLE_CHANGE:
      return { ...state, activePin: action.payload }
    case TOGGLE_MODAL:
      return { ...state, pins: action.payload, isOpen: !state.isOpen }
    case TOGGLE_SUMMARY:
      return { ...state, showSummary: !state.showSummary }
    case TOGGLE_COLLAPSE:
      return { ...state, collapsedPin: action.payload, activePin: {} }
    case TOGGLE_DRAGGING: {
      // isDraggingEnabled
      const pins = [...state.pins.filter(pin => pin.saved)]
      if (action.payload) {
        return {
          ...state,
          activePin: null,
          pins,
          isDraggingEnabled: action.payload
        }
      }
      return { ...state, isDraggingEnabled: action.payload }
    }
    case FETCH_BLUEPRINT_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_BLUEPRINT_FAIL:
      return { ...state, isLoadingFetch: false, error: null }
    case FETCH_BLUEPRINT_SUCCESS: {
      const { items } = action.payload
      const pins = items.map(item => ({
        item,
        id: item.id,
        saved: true
      }))
      const blueprint = { ...action.payload, ...state.blueprint }
      blueprint.image = `${axios.defaults.baseURL}/v1/files/${action.payload.fileId}?token=${action.payload.token}`
      blueprint.name = action.payload.name
      return {
        ...state,
        isLoadingFetch: false,
        error: null,
        pins,
        blueprint
      }
    }
    case BLUEPRINT_CLICK: {
      const activePin = { ...action.payload.activePin }
      const pins = [...action.payload.pins]
      return {
        ...state,
        pins,
        activePin,
        isOpen: !state.isOpen
      }
    }
    case PIN_CLICK: {
      const hasPayload = !!action.payload
      if (hasPayload) {
        const activePin = { ...action.payload.activePin }
        const pins = [...action.payload.pins]
        return {
          ...state,
          pins,
          activePin,
          canCreateBlueprintItem: !state.canCreateBlueprintItem
        }
      }
      return { ...state, pinClicked: !state.pinClicked }
    }
    case UPDATE_PIN_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_PIN_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false, error: action.payload }
    case UPDATE_PIN_SUCCESS: {
      const activePin = {
        item: action.payload,
        id: action.payload.id,
        saved: true
      }
      const pins = replace({ id: activePin.id }, activePin, state.pins)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        activePin,
        pins
      }
    }
    case CREATE_PIN_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_PIN_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false, error: action.payload }
    case CREATE_PIN_SUCCESS: {
      const { item } = action.payload
      const activePin = {
        item,
        id: item.id,
        saved: true
      }
      const pins = state.pins.filter(p => p.saved)
      pins.unshift(activePin)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        activePin,
        pins,
        isOpen: !state.isOpen
      }
    }
    case EDIT_PIN_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case EDIT_PIN_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false, error: action.payload }
    case EDIT_PIN_SUCCESS: {
      const activePin = {
        item: action.payload,
        id: action.payload.id,
        saved: true
      }
      const pins = replace(activePin, activePin, state.pins)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        activePin,
        pins,
        isOpen: !state.isOpen
      }
    }
    case FILE_UPLOAD_START:
      return { ...state, isLoadingUpload: true }
    case FILE_UPLOAD_FAIL:
      return { ...state, isLoadingUpload: false, error: action.payload }
    case FILE_UPLOAD_SUCCESS: {
      const activePin = { ...state.activePin }
      if (activePin.item.files && activePin.item.files.length > 0)
        activePin.item.files.push(action.payload)
      else activePin.item.files = [action.payload]
      const pins = replace({ id: activePin.id }, activePin, [...state.pins])
      return {
        ...state,
        isLoadingUpload: false,
        pins,
        activePin
      }
    }
    case REMOVE_PIN_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_PIN_FAIL:
      return { ...state, isLoadingRemove: false, error: action.payload }
    case REMOVE_PIN_SUCCESS: {
      let pins = [...state.pins]
      pins = replace({ id: action.payload }, null, [...pins])
      return {
        ...state,
        isLoadingRemove: false,
        error: false,
        pins,
        pinClicked: false,
        canCreateBlueprintItem: true
      }
    }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default blueprintDetail
