import {
  SignalCellularConnectedNoInternet0Bar,
  SignalWifiOffOutlined
} from '@material-ui/icons'
import { Stack } from '@mui/material'
import Button from 'src/components/Button'
import { useNetworkStatus } from 'src/hooks/useNetworkStatus'

export const OfflineOverlay = () => {
  const { online } = useNetworkStatus()

  if (online) {
    return null
  }

  return (
    <Stack
      position={'fixed'}
      zIndex={9999999}
      top={0}
      right={0}
      bottom={0}
      left={0}
      bgcolor={'#fff'}
      padding={'24px'}
      alignItems='center'
      justifyContent={'center'}
    >
      <Stack alignItems={'center'} justifyContent={'center'} maxWidth={400}>
        <SignalCellularConnectedNoInternet0Bar
          style={{ fontSize: 64, color: 'rgba(130,0,0,0.5)', marginBottom: 20 }}
        />
        <ol>
          <li>Kontrollera din internetanslutning</li>
          <li>Stäng/avsluta appen och öppna på nytt</li>
          <li>
            Gå in via webläsare (t.ex Samsung Internet, Chrome, Safari, Egde,
            Firefox, Opera)
          </li>
          <li>
            Rensa cache & webhistorik i webläsaren. Det görs under
            inställningar/personliga surfdata/radera webbläsardata (beroende på
            vilken webbläsare eller enhet som används)
          </li>
          <li>Ring support, 031-55 34 00.</li>
        </ol>
        <Button
          variant='primary'
          onClick={() => window.location.reload()}
          style={{ marginTop: 20 }}
        >
          Försök igen
        </Button>
      </Stack>
    </Stack>
  )
}
