/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { CircularProgress } from '@material-ui/core'
import Typography from './Typography'

export const EVENT_NAME = 'PROSERO_UPDATE'
export const UpdateEvent = () => new Event(EVENT_NAME)

const styles = {
  root: {
    position: 'fixed',
    zIndex: 1090,
    backgroundColor: 'rgba(0,0,0,0.2)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 40px',
    borderRadius: 3,
    boxShadow:
      '0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #e1e1e1',
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  '@media (max-width: 900px)': {}
}

const UpdateOverlay = ({ classes }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const listener = () => {
      setVisible(true)
    }
    window.addEventListener(EVENT_NAME, listener)
    return () => window.removeEventListener(EVENT_NAME, listener)
  }, [])

  if (!visible) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.modal}>
        <Typography noWrap bold variant='subtitle'>
          {global._('UpdateOverlay.SystemUpdate')}
        </Typography>
        <Typography noWrap variant='body' style={{ margin: '16px 0' }}>
          {global._('UpdateOverlay.PleaseWait')}
        </Typography>
        <CircularProgress
          style={{ color: 'var(--color-circular-progress)', marginTop: 16 }}
          thickness={4}
          size={40}
        />
      </div>
    </div>
  )
}

UpdateOverlay.propTypes = {
  classes: PropTypes.object.isRequired
}

UpdateOverlay.defaultProps = {}

export default injectSheet(styles)(UpdateOverlay)
