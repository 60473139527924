import axios from 'axios'
import { setSnackbar } from './snackbar'

const initialState = {
  user: {},
  organisation: {},
  readOnly: true,
  registered: false,
  backgroundColor: '#9fa1a3',
  pwStrength: 0,
  error: null,
  isLoadingRegister: false,
  isLoadingVerify: false
}

/* HANDLE_SAFETY_LEVEL Action */
const HANDLE_SAFETY_LEVEL = 'register/HANDLE_SAFETY_LEVEL'
// data contains backgroundColor and pwStrength
export const handleSafetyLevel = (backgroundColor, pwStrength) => ({
  type: HANDLE_SAFETY_LEVEL,
  payload: { backgroundColor, pwStrength }
})

/* HANDLE_CHANGE Action */
const HANDLE_CHANGE = 'register/HANDLE_CHANGE'
export const handleChange = user => ({
  type: HANDLE_CHANGE,
  payload: user
})

/* REGISTER_USER Action */
const REGISTER_USER_START = 'REGISTER_USER_START'
const registerUserStart = () => ({
  type: REGISTER_USER_START
})

const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
const registerUserFail = err => ({
  type: REGISTER_USER_FAIL,
  payload: err
})

const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})

export const register = (user, token) => async dispatch => {
  dispatch(registerUserStart())
  try {
    const response = await axios.post('/v1/users', { user, token })
    dispatch(registerUserSuccess(response.data))
  } catch (err) {
    dispatch(registerUserFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* VERIFY_TOKEN Action */
const VERIFY_TOKEN_START = 'VERIFY_TOKEN_START'
const verifyTokenStart = () => ({
  type: VERIFY_TOKEN_START
})

const VERIFY_TOKEN_FAIL = 'VERIFY_TOKEN_FAIL'
const verifyTokenFail = err => ({
  type: VERIFY_TOKEN_FAIL,
  payload: err
})

const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
const verifyTokenSuccess = token => ({
  type: VERIFY_TOKEN_SUCCESS,
  payload: token
})

export const verify = token => async dispatch => {
  dispatch(verifyTokenStart())
  try {
    const response = await axios.post('/v1/users/signup/check', { token })
    dispatch(verifyTokenSuccess(response.data))
  } catch (err) {
    dispatch(verifyTokenFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const Register = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return { ...state, user: action.payload }
    case HANDLE_SAFETY_LEVEL:
      return {
        ...state,
        backgroundColor: action.payload.backgroundColor,
        pwStrength: action.payload.pwStrength
      }
    case REGISTER_USER_START:
      return { ...state, isLoadingRegister: true }
    case REGISTER_USER_FAIL:
      return { ...state, isLoadingRegister: false, error: action.payload }
    case REGISTER_USER_SUCCESS: {
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('expires', action.payload.expires)
      return {
        ...state,
        isLoadingRegister: false,
        register: true,
        readOnly: true,
        registered: true,
        user: action.payload.user,
        error: null
      }
    }
    case VERIFY_TOKEN_START:
      return { ...state, isLoadingVerify: true, readOnly: true }
    case VERIFY_TOKEN_FAIL:
      return { ...state, isLoadingVerify: false, error: action.payload }
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoadingVerify: false,
        readOnly: false,
        organisation: action.payload,
        error: null
      }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default Register
