import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

let environmentName = 'production'
if (window?.location?.hostname?.includes('localhost')) {
  environmentName = 'development'
} else if (window?.location?.hostname?.includes('portal-test')) {
  environmentName = 'staging'
}

if (environmentName !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_VERSION,
    environment: environmentName,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const target = hint?.event?.target
        if (target?.innerText) {
          breadcrumb.message += ` (${target.innerText.substring(0, 80)})`
        }
      }
      return breadcrumb
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
  document.addEventListener('DOMContentLoaded', () => {
    const cpm = {}
    if (
      window &&
      window.cookiehub &&
      typeof window.cookiehub.load === 'function'
    ) {
      window.cookiehub.load(cpm)
    }
  })
}
