import axios from 'axios'
import { stringify } from 'qs'
import { replace, push } from '../../../helpers/immutable'
import { setSnackbar } from '../snackbar'

const initialState = {
  realEstate: null,
  blueprint: null,
  isOpen: false,
  filter: {
    orderBy: null,
    order: 'asc',
    query: null
  },
  isLoadingFetch: false,
  isLoadingFilterFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingRemove: false
}

const TOGGLE_MODAL = 'realEstateDetail/TOGGLE_MODAL'
export const toggle = blueprint => ({
  type: TOGGLE_MODAL,
  payload: blueprint
})

const HANDLE_CHANGE = 'realEstateDetail/HANDLE_CHANGE'
export const handleChange = blueprint => ({
  type: HANDLE_CHANGE,
  payload: blueprint
})

const HANDLE_FILE = 'realEstateDetail/HANDLE_FILE'
export const handleFile = blueprint => ({
  type: HANDLE_FILE,
  payload: blueprint
})

const CREATE_BLUEPRINT_START = 'realEstateDetail/CREATE_BLUEPRINT_START'
const createBlueprintStart = () => ({
  type: CREATE_BLUEPRINT_START
})

const CREATE_BLUEPRINT_FAIL = 'realEstateDetail/CREATE_BLUEPRINT_FAIL'
const createBlueprintFail = err => ({
  type: CREATE_BLUEPRINT_FAIL,
  payload: err
})

const CREATE_BLUEPRINT_SUCCESS = 'realEstateDetail/CREATE_BLUEPRINT_SUCCESS'
const createBlueprintSuccess = blueprint => ({
  type: CREATE_BLUEPRINT_SUCCESS,
  payload: blueprint
})

export const createBlueprint = blueprint => async dispatch => {
  dispatch(createBlueprintStart())
  try {
    const response = await axios.post('/v1/blueprint', blueprint)
    dispatch(createBlueprintSuccess(response.data))
    dispatch(
      setSnackbar(`Planritning - ${response.data.name} har skapats.`, 'success')
    )
  } catch (err) {
    dispatch(createBlueprintFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* FETCH_ALL Action */
const FETCH_ONE_START = 'realEstateDetail/FETCH_ONE_START'
const fetchOneStart = () => ({
  type: FETCH_ONE_START
})

const FETCH_ONE_FAIL = 'realEstateDetail/FETCH_ONE_FAIL'
const fetchOneFail = err => ({
  type: FETCH_ONE_FAIL,
  payload: err
})

const FETCH_ONE_SUCCESS = 'realEstateDetail/FETCH_ONE_SUCCESS'
const fetchOneSuccess = realEstate => ({
  type: FETCH_ONE_SUCCESS,
  payload: realEstate
})

export const fetch = id => async dispatch => {
  dispatch(fetchOneStart())
  try {
    const response = await axios.get(`/v1/real-estates/${id}?blueprints=true`)
    dispatch(fetchOneSuccess(response.data))
  } catch (err) {
    dispatch(fetchOneFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* HANDLE_FILTER Action */
const HANDLE_FILTER = 'realEstateDetail/HANDLE_FILTER'
const handleFilter = filter => ({
  type: HANDLE_FILTER,
  payload: filter
})

const FILTER_BLUEPRINTS_START = 'realEstateDetail/FILTER_BLUEPINTS_START'
const filterBlueprintsStart = () => ({
  type: FILTER_BLUEPRINTS_START
})

const FILTER_BLUEPRINTS_FAIL = 'realEstateDetail/FILTER_BLUEPINTS_FAIL'
const filterBlueprintsFail = err => ({
  type: FILTER_BLUEPRINTS_FAIL,
  payload: err
})

const FILTER_BLUEPRINTS_SUCCESS = 'realEstateDetail/FILTER_BLUEPINTS_SUCCESS'
const filterBlueprintsSuccess = blueprints => ({
  type: FILTER_BLUEPRINTS_SUCCESS,
  payload: blueprints
})

export const filterFetch = filter => async (dispatch, getState) => {
  const { realEstate } = getState().realEstateDetail
  const sort = stringify(filter)
  dispatch(handleFilter(filter))
  dispatch(filterBlueprintsStart())
  try {
    const response = await axios.get(`/v1/blueprints/${realEstate.id}?${sort}`)
    dispatch(filterBlueprintsSuccess(response.data))
  } catch (err) {
    dispatch(filterBlueprintsFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* EDIT_BLUEPRINT Action */
const EDIT_BLUEPRINT_START = 'realEstateDetail/EDIT_BLUEPRINT_START'
const editBlueprintStart = () => ({
  type: EDIT_BLUEPRINT_START
})

const EDIT_BLUEPRINT_FAIL = 'realEstateDetail/EDIT_BLUEPRINT_FAIL'
const editBlueprintFail = err => ({
  type: EDIT_BLUEPRINT_FAIL,
  payload: err
})

const EDIT_BLUEPRINT_SUCCESS = 'realEstateDetail/EDIT_BLUEPRINT_SUCCESS'
const editBlueprintSuccess = id => ({
  type: EDIT_BLUEPRINT_SUCCESS,
  payload: id
})

export const editBlueprint = () => async (dispatch, getState) => {
  const { blueprint } = getState().realEstateDetail
  dispatch(editBlueprintStart())
  try {
    const response = await axios.put(
      `/v1/blueprints/${blueprint.id}`,
      blueprint
    )
    dispatch(editBlueprintSuccess(response.data))
    dispatch(
      setSnackbar(
        `Planritning - ${response.data.name} har uppdaterats.`,
        'success'
      )
    )
  } catch (err) {
    dispatch(editBlueprintFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REMOVE_BLUEPRINT Action */
const REMOVE_BLUEPRINT_START = 'realEstateDetail/REMOVE_BLUEPRINT_START'
const removeBlueprintStart = () => ({
  type: REMOVE_BLUEPRINT_START
})

const REMOVE_BLUEPRINT_FAIL = 'realEstateDetail/REMOVE_BLUEPRINT_FAIL'
const removeBlueprintFail = err => ({
  type: REMOVE_BLUEPRINT_FAIL,
  payload: err
})

const REMOVE_BLUEPRINT_SUCCESS = 'realEstateDetail/REMOVE_BLUEPRINT_SUCCESS'
const removeBlueprintSuccess = id => ({
  type: REMOVE_BLUEPRINT_SUCCESS,
  payload: id
})

export const remove = blueprint => async dispatch => {
  dispatch(removeBlueprintStart())
  try {
    await axios.delete(`/v1/blueprints/${blueprint.id}`)
    dispatch(removeBlueprintSuccess(blueprint.id))
    dispatch(
      setSnackbar(`Planritning - ${blueprint.name} borttagen`, 'success')
    )
  } catch (err) {
    dispatch(removeBlueprintFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const realestateDetail = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL: {
      if (!action.payload) {
        return { ...state, isOpen: !state.isOpen }
      }
      return { ...state, blueprint: action.payload, isOpen: !state.isOpen }
    }
    case HANDLE_FILTER:
      return { ...state, filter: action.payload }
    case HANDLE_CHANGE:
      return { ...state, blueprint: action.payload }
    case HANDLE_FILE:
      return { ...state, blueprint: action.payload, isOpen: true }
    case FETCH_ONE_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ONE_FAIL:
      return { ...state, isLoadingFetch: false, error: action.payload }
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        isLoadingFetch: false,
        error: null,
        realEstate: action.payload[0]
      }
    case FILTER_BLUEPRINTS_START:
      return { ...state, isLoadingFilterFetch: true }
    case FILTER_BLUEPRINTS_FAIL:
      return {
        ...state,
        isLoadingFilterFetch: false,
        error: action.payload
      }
    case FILTER_BLUEPRINTS_SUCCESS: {
      const realEstate = { ...state.realEstate }
      realEstate.blueprints = action.payload
      return {
        ...state,
        isLoadingFilterFetch: false,
        error: null,
        realEstate
      }
    }
    case EDIT_BLUEPRINT_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case EDIT_BLUEPRINT_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case EDIT_BLUEPRINT_SUCCESS: {
      const realEstate = { ...state.realEstate }
      realEstate.blueprints = replace(
        { id: action.payload.id },
        action.payload,
        [...realEstate.blueprints]
      )
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        blueprint: null,
        error: null,
        realEstate,
        isOpen: false
      }
    }
    case CREATE_BLUEPRINT_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_BLUEPRINT_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case CREATE_BLUEPRINT_SUCCESS: {
      const realEstate = { ...state.realEstate }
      realEstate.blueprints = push(action.payload, [...realEstate.blueprints])
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        blueprint: null,
        error: null,
        realEstate,
        isOpen: false
      }
    }
    case REMOVE_BLUEPRINT_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_BLUEPRINT_FAIL:
      return { ...state, isLoadingRemove: false, error: action.payload }
    case REMOVE_BLUEPRINT_SUCCESS: {
      const realEstate = { ...state.realEstate }
      realEstate.blueprints = replace({ id: action.payload }, null, [
        ...realEstate.blueprints
      ])
      return {
        ...state,
        isLoadingRemove: false,
        error: null,
        realEstate
      }
    }
    case 'CLEAR_STATE':
      return initialState
    default:
      return state
  }
}

export default realestateDetail
