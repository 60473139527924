const DocumentTypes = {
  ServiceAgreement: 'SERVICE_AGREEMENT',
  LicenseAgreement: 'LICENSE_AGREEMENT',
  InspectionReport: 'INSPECTION_REPORT',
  Manual: 'MANUAL',
  Offer: 'OFFER',
  Other: 'OTHER',
  CustomerDocument: 'CUSTOMER_DOCUMENT'
}

const DocumentStatus = {
  AwaitingApproval: 'AWAITING_APPROVAL',
  Accepted: 'ACCEPTED',
  Rejected: 'REJECTED',
  None: 'NONE'
}

const DeviceTypes = {
  Lock: 'LOCK',
  Device: 'DEVICE'
}

const CaseTypes = {
  Key: 'KEY',
  Service: 'SERVICE',
  Support: 'SUPPORT',
  Relocation: 'RELOCATION',
  Claim: 'CLAIM',
  Feedback: 'FEEDBACK',
  Order: 'ORDER',
  Other: 'OTHER'
}

const CaseListUrlTypes = {
  DEFAULT: '/cases',
  CLAIM: '/cases/claims',
  FEEDBACK: '/cases/feedback',
  UNREAD: '/cases/unread',
  ORDER: '/cases/orders'
}

const CaseIcons = {
  KEY: 'vpn_key',
  SUPPORT: 'help',
  SERVICE: 'build',
  RELOCATION: 'check_circle',
  OTHER: 'more_horiz',
  CLAIM: 'phonelink_off',
  FEEDBACK: 'feedback',
  ORDER: 'shopping_cart'
}

const CaseTypeActions = {
  KEY: [
    'NEW',
    'NEW_ORDER',
    'LOST',
    'RECLAIM',
    'BLOCK_KEY',
    'BLOCK_AND_REPLACE_KEY',
    'CHANGE_PERMISSION',
    'OTHER'
  ],
  SERVICE: ['TROUBLESHOOTING', 'INSTALLATION', 'OTHER'],
  SUPPORT: ['SOFTWARE', 'LOCK_SYSTEM', 'PORTAL', 'OTHER'],
  RELOCATION: [],
  CLAIM: [],
  ORDER: ['KEY_REPLACEMENT', 'KEY_COPY']
}

const CaseTypeActionSubcategories = {
  KEY: {
    NEW: ['MECHANICAL', 'DIGITAL', 'RFID'],
    BLOCK_KEY: ['DIGITAL', 'RFID'],
    BLOCK_AND_REPLACE_KEY: ['MECHANICAL', 'DIGITAL', 'RFID']
  }
}

const CaseStatus = {
  New: 'NEW',
  Started: 'STARTED',
  Resting: 'RESTING',
  Closed: 'CLOSED'
}

const CaseInvoiceStatus = {
  NotChargeable: 'NOT_CHARGEABLE',
  NotInvoiced: 'NOT_INVOICED',
  Invoiced: 'INVOICED',
  NotInvoicedCall: 'NOT_INVOICED_CALL',
  IncludedInServiceAgreement: 'SERVICE_AGREEMENT',
  Paid: 'PAID'
}

const OrderStatus = {
  New: 'NEW',
  Started: 'STARTED',
  Collected: 'COLLECTED',
  Closed: 'CLOSED'
}

const ClaimProductTypes = ['C10S.10', 'C10S.1', 'H10S.3.60']
const ClaimReplacement = {
  OwnStock: 'OWN_STOCK',
  PartnerStock: 'PARTNER_STOCK',
  None: 'NONE'
}
const ClaimFactoryReset = {
  Yes: 'YES',
  NotPossible: 'NOT_POSSIBLE',
  Support: 'SUPPORT'
}

const ClaimReturnTo = {
  SendToManufacturer: 'MANUFACTURER',
  SendToPartner: 'PARTNER'
}

const OrganisationTypes = {
  SystemAdmin: 'SYSTEM_ADMIN',
  ServiceDesk: 'SERVICEDESK',
  Partner: 'PARTNER',
  Customer: 'CUSTOMER',
  Unbound: 'UNBOUND',
  Guest: 'GUEST'
}

const Country = {
  SE: 'SE',
  NO: 'NO'
}

const KeyStatus = {
  ACTIVE: 'ACTIVE',
  LOST: 'LOST',
  DAMAGED: 'DAMAGED',
  BLOCKED: 'BLOCKED',
  DISCONTINUED: 'DISCONTINUED'
}

const KeyType = {
  MECHANICAL: 'MECHANICAL',
  DIGITAL: 'DIGITAL',
  RFID: 'RFID'
}

const KeyInventoryStatus = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED'
}

const KeyInventoryApprovalStatus = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED'
}

const UserRoles = {
  SystemAdmin: 'SYSTEM_ADMIN',
  Support: 'SUPPORT',
  PartnerAdmin: 'PARTNER_ADMIN',
  CustomerAdmin: 'CUSTOMER_ADMIN',
  CustomerAdminGuest:
    'CUSTOMER_ADMIN_GUEST' /* Will be limited to accessing properties only */,
  Customer: 'CUSTOMER',
  Resident: 'RESIDENT',
  Temporary: 'TEMPORARY',
  Guest: 'GUEST'
}

const UserAssignableRoles = {
  SYSTEM_ADMIN: [
    'SYSTEM_ADMIN',
    'SUPPORT',
    'PARTNER_ADMIN',
    'CUSTOMER_ADMIN',
    'CUSTOMER_ADMIN_GUEST',
    'CUSTOMER',
    'RESIDENT'
  ],
  SUPPORT: [],
  PARTNER_ADMIN: [
    'PARTNER_ADMIN',
    'CUSTOMER_ADMIN',
    'CUSTOMER_ADMIN_GUEST',
    'CUSTOMER',
    'RESIDENT'
  ],
  CUSTOMER_ADMIN: ['CUSTOMER_ADMIN', 'CUSTOMER', 'RESIDENT'],
  CUSTOMER_ADMIN_GUEST: [],
  CUSTOMER: [],
  RESIDENT: [],
  GUEST: []
}

const BlueprintObjectTypes = {
  Barrier: 'Barrier',
  CCTVCentral: 'CCTVCentral',
  CCTVCamera: 'CCTVCamera',
  CCTVSwitch: 'CCTVSwitch',
  Door: 'Door',
  Hotspot: 'Hotspot',
  Hatch: 'Hatch'
}

const BlueprintItemTypes = {
  CardReader: 'CardReader',
  Device: 'Device',
  Cylinder: 'Cylinder',
  Padlock: 'Padlock'
}

const BlueprintObjectItemTypes = {
  Barrier: [BlueprintItemTypes.Cylinder],
  CCTVCentral: [],
  CCTVCamera: [],
  CCTVSwitch: [],
  Door: [
    BlueprintItemTypes.CardReader,
    BlueprintItemTypes.Device,
    BlueprintItemTypes.Cylinder,
    BlueprintItemTypes.Padlock
  ],
  Hotspot: [],
  Hatch: [BlueprintItemTypes.Cylinder]
}

/* Custom definition of field types for blueprintObjectItemAttributes */
//
const AttributeFieldTypes = {
  Input: 'SingleInput',
  MultilineInput: 'MultilineInput',
  Radio: 'Radio',
  Dropdown: 'Dropdown'
}

const AttributeFields = {
  Placement: {
    key: 'placement',
    type: AttributeFieldTypes.Radio,
    required: true,
    values: ['OUT', 'IN']
  },
  CylinderHeight: {
    key: 'cylinderHeight',
    type: AttributeFieldTypes.Dropdown,
    placeholder: true, // Will be translated using `key`, e.g. 'AttributeFields.Placeholder.CylinderHeight'
    helpText: true, // Will be translated using `key`, e.g. 'AttributeFields.HelpText.CylinderHeight'
    values: [null, 6, 8, 11, 13, 16, 18, 21, 24, 27, 30, 33] // Will be translated using `key`, e.g. AttributeFields.Values.cylinderHeight.$
  },
  PadlockClass: {
    key: 'padlockClass',
    type: AttributeFieldTypes.Dropdown,
    placeholder: true,
    helpText: true,
    values: [null, 2, 3, 4]
  },
  HasChain: {
    key: 'hasChain',
    type: AttributeFieldTypes.Radio,
    values: ['YES', 'NO']
  },
  PadlockBracketHeight: {
    key: 'bracketHeight',
    type: AttributeFieldTypes.Radio,
    values: ['HIGH', 'LOW']
  },
  Notes: {
    key: 'notes',
    placeholder: true,
    helpText: true,
    type: AttributeFieldTypes.MultilineInput
  }
}

const ItemAttributes = {
  CardReader: [AttributeFields.Notes],
  Device: [AttributeFields.Notes],
  Cylinder: [
    AttributeFields.Placement,
    AttributeFields.CylinderHeight,
    AttributeFields.Notes
  ],
  Padlock: [
    AttributeFields.Placement,
    AttributeFields.HasChain,
    AttributeFields.PadlockBracketHeight,
    AttributeFields.PadlockClass,
    AttributeFields.Notes
  ]
}

const DefaultSpaces = {
  SE: [
    'Barnvagnsrum',
    'Cykelrum',
    'Elcentral',
    'Elnisch',
    'Elrum',
    'Entré',
    'Fastighetsförråd',
    'Fläktrum',
    'Fönster',
    'Fönsterdörr',
    'Garage',
    'Garageport',
    'Grovsoprum',
    'Gårdsentré',
    'Hissmaskinrum',
    'Korridor',
    'Källare',
    'Kök',
    'Lokal',
    'Lägenhet',
    'Lägenhetsförråd',
    'Nödutgång',
    'Passage',
    'Sidoentré',
    'Soprum',
    'Sprinklercentral',
    'Städ',
    'Taklucka',
    'Teknikutrymme',
    'Torkrum',
    'Trapphus',
    'Tvättstuga',
    'Undercentral',
    'WC'
  ],
  NO: [
    'Barnevogn',
    'Sykkelrom',
    'Elcentral',
    'Elnisch',
    'Elrum',
    'Inngang',
    'Eiendomslagring',
    'Vifterom',
    'Vindu',
    'Vindusdør',
    'Garasje',
    'Garageport',
    'Grovsoprum',
    'Gårdsentré',
    'Heiserom',
    'Korridor',
    'Kjeller',
    'Kjøkken',
    'Lokalt',
    'Leilighet',
    'Leilighetslagring',
    'Nødutgang',
    'Passasje',
    'Sideinngang',
    'Soprum',
    'Sprinklercentral',
    'Ren',
    'Soltak',
    'Teknologirom',
    'Tørkerom',
    'Trapphus',
    'Klesvask',
    'Subcentral',
    'WC'
  ]
}

const DeviceConfig = {
  PULSE: 'PULSE',
  SAFETRON3600: 'SAFETRON3600'
}

const DeviceOperation = {
  PULSE: 'pulse',
  LOCK: 'lock',
  UNLOCK: 'unlock'
}

const LogLevels = {
  SILLY: 1,
  DEBUG: 2,
  VERBOSE: 3,
  INFO: 4,
  WARNING: 5,
  ERROR: 6
}

const LogAgentTypes = {
  USER: 'USER',
  DELEGATED_ACCESS_USER: 'DELEGATED_ACCESS_USER',
  SYSTEM: 'SYSTEM'
}

const LogAgentTypeIdentifiers = {
  IOT_SERVER: 1,
  PARTNER_API: 2,
  CORE_API: 3
}

const LogOperations = {
  DEVICE_ONLINE: 'DEVICE_ONLINE',
  DEVICE_OFFLINE: 'DEVICE_OFFLINE',
  LOCK: 'LOCK',
  LOCK_TECHNICIAN: 'LOCK_TECHNICIAN',
  UNLOCK: 'UNLOCK',
  UNLOCK_TECHNICIAN: 'UNLOCK_TECHNICIAN',
  PULSE: 'PULSE',
  PULSE_TECHNICIAN: 'PULSE_TECHNICIAN'
}

const LogTypes = {
  KEE: 'KEE'
}

const LogTargetTypes = {
  LOCK: 'LOCK',
  DEVICE: 'DEVICE'
}

const ProductKeys = {
  KEY_COPY: 'KEY_COPY',
  KEY_REPLACEMENT: 'KEY_REPLACEMENT',
  KEY_COPY_ON_CALL: 'KEY_COPY_ON_CALL'
}

const ProductVariants = {
  Keys: {
    MECHANICAL: 'MECHANICAL',
    DIGITAL: 'DIGITAL',
    RFID: 'RFID'
  }
}

const ProductSKU = {
  KEY_REPLACEMENT: '1001',
  KEY_COPY: '1002',
  KEY_COPY_ON_CALL: '1003'
}

const VATAmount = 0.25

const PaymentMethods = {
  SWISH: 'SWISH',
  NETS_EASY: 'NETS_EASY'
}

const PaymentStatus = {
  NEW: 'NEW', // Created but no external request sent
  PENDING: 'PENDING', // External request sent to payment provider
  PAID: 'PAID',
  CANCELLED: 'CANCELLED'
}

const OrganisationServices = {
  PROPERTIES: 'PROPERTIES',
  DOCUMENTS: 'DOCUMENTS',
  CASES: 'CASES',
  CASES_FOR_USERS: 'CASES_FOR_USERS',
  KEE: 'KEE',
  KEY_MANAGEMENT: 'KEY_MANAGEMENT',
  KEY_ORDERS: 'KEY_ORDERS',
  BOOKING: 'BOOKING'
}

const CertificateTypes = {
  SWISH: 'SWISH'
}

const ContactPageURL = 'https://digitallassmed.se/kontakta-oss/'

const ServiceURLs = {
  KEY_MANAGEMENT: '/keys/select-organisation',
  KEY_ORDERS: '/orders'
}

const ImportStorageKeys = {
  USERS: 'import.users.preview.data',
  APARTMENTS: 'import.apartments.preview.data',
  KEYS: 'import.keys.preview.data'
}

const ElectronicIdentityServices = {
  AUTO: 'AUTO',
  /* Norway */
  NO_BANKID: 'no_bankid',
  // NO_BANKID_MOBILE: 'no_bidmob',
  // NO_BUYPASS: 'no_buypass',
  /* Denmark */
  DK_NEMID_JS: 'dk_nemid_js',
  DK_NEMID_OPENSIGN: 'dk_nemid-opensign',
  DK_MITID: 'mitid',
  /* Sweden */
  SE_BANKID: 'se_bankid',
  /* Finland */
  FI_TUPAS: 'fi_tupas',
  FI_MOBIILIVARMENNE: 'fi_mobiilivarmenne',
  /* General */
  PASSPORT_READER: 'passport_reader',
  VERIMI: 'verimi',
  NETS_SMS: 'nets_sms'
}

const ReportParts = {
  USERS: 'users',
  PARTNER_ADMIN: 'users-partner-admin',
  CUSTOMER_ADMIN: 'users-customer-admin',
  CUSTOMER: 'users-customer',
  RESIDENT: 'users-resident',
  GUEST: 'users-guest',
  APARTMENTS: 'apartments',
  CUSTOMERS: 'customers',
  PARTNERS: 'partners',
  PROPERTIES: 'properties',
  BLUEPRINTS: 'blueprints',
  CASES: 'cases',
  ORDERS: 'orders',
  KEYS: 'keys',
  KEYS_MECHANICAL: 'keys-mechanical',
  KEYS_DIGITAL: 'keys-digital',
  KEYS_TAGS: 'keys-tags',
  KEE_OPEN: 'kee-open',
  KEE_DEVICES: 'kee-devices'
}

// Youtube video ids
const InstructionsVideoIds = {
  SE: {
    DOCUMENTS: {
      PARTNER_ADMIN: 'rbg2MSjmAm8',
      CUSTOMER_ADMIN: 'qjXRYIiWzHs'
    },
    PROPERTIES: 'W2LnASndCMY',
    CASES: 'J_f1MWJ-nV0',
    MY_KEYS: 'NEZ2VwBjMlw',
    KEY_MANAGEMENT: {
      BASE: 'Da6X1jrg1Dw',
      REPOSITORIES: 'kgi5X8OgIZM',
      LOCK_SYSTEMS: '8v0nQEbGS_o',
      INVENTORY: 'GKvVqZHgEK0',
      KEYS: 'kYumJtBiUAs'
    },
    ADMIN: {
      CUSTOMERS: 'wFlihNl0IXw',
      USERS: 'oQ-t5KkAsjk',
      ACCESS_GROUPS: 'wQcHe1w_ig4',
      DEVICES: 'xSo7R1-RDFM'
    }
  },
  NO: {
    DOCUMENTS: {
      PARTNER_ADMIN: '',
      CUSTOMER_ADMIN: ''
    },
    PROPERTIES: '',
    CASES: '',
    MY_KEYS: '',
    KEY_MANAGEMENT: '',
    ADMIN: {
      CUSTOMERS: '',
      USERS: ''
    }
  }
}

const Languages = {
  SE: 'sv-SE',
  NO: 'nb-NO'
}

const ShippingTypes = {
  COLLECT: 'COLLECT',
  SEND: 'SEND'
}

module.exports = {
  DocumentTypes,
  DocumentStatus,
  DeviceTypes,
  CaseTypes,
  CaseListUrlTypes,
  CaseIcons,
  CaseTypeActions,
  CaseTypeActionSubcategories,
  CaseStatus,
  CaseInvoiceStatus,
  OrderStatus,
  ClaimProductTypes,
  ClaimReplacement,
  ClaimFactoryReset,
  ClaimReturnTo,
  OrganisationTypes,
  Country,
  KeyStatus,
  KeyType,
  KeyInventoryStatus,
  KeyInventoryApprovalStatus,
  UserRoles,
  UserAssignableRoles,
  BlueprintObjectTypes,
  BlueprintItemTypes,
  BlueprintObjectItemTypes,
  AttributeFieldTypes,
  AttributeFields,
  ItemAttributes,
  DefaultSpaces,
  DeviceConfig,
  DeviceOperation,
  LogOperations,
  LogTypes,
  LogTargetTypes,
  LogLevels,
  LogAgentTypes,
  LogAgentTypeIdentifiers,
  ProductKeys,
  ProductVariants,
  ProductSKU,
  VATAmount,
  PaymentMethods,
  PaymentStatus,
  OrganisationServices,
  CertificateTypes,
  ContactPageURL,
  ServiceURLs,
  ImportStorageKeys,
  ElectronicIdentityServices,
  ShippingTypes,
  ReportParts,
  InstructionsVideoIds,
  Languages
}
