const initialState = {
  variant: 'success',
  message: null,
  openSnackbar: false
}

const HANDLE_SNACKBAR = 'snackbar/HANDLE_SNACKBAR'
const handleSnackbar = (message, variant) => ({
  type: HANDLE_SNACKBAR,
  payload: { message, variant }
})

const TOGGLE_SNACKBAR = 'snackbar/TOGGLE_SNACKBAR'
export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR
})

export const setSnackbar =
  (message, variant = 'success') =>
  dispatch => {
    dispatch(handleSnackbar(message, variant))
  }

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SNACKBAR:
      return {
        ...state,
        variant: action.payload.variant,
        message: action.payload.message,
        openSnackbar: true
      }
    case TOGGLE_SNACKBAR:
      return { ...state, openSnackbar: false }
    default:
      return state
  }
}

export default snackbar
