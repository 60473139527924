// / <reference path="types/global.d.ts" />
import './init'
import 'src/utils/deferBeforeInstallPromptEvent'
import 'src/utils/localization'
// import { setPWA } from 'src/utils/PWASelector'
import { createRoot } from 'react-dom/client'
import './theme.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

serviceWorker.register()
