const deferred = { event: null }
// console.log('Capturing beforeinstallprompt')
const listener = e => {
  // console.log('captured beforeinstallprompt')
  e.preventDefault()
  deferred.event = e
  global.__bip_event__ = e
  // console.log('deferred.event', deferred.event)
  // window.removeEventListener('beforeinstallprompt', listener)
  const bipEvent = new CustomEvent('__bip_event__')
  window.dispatchEvent(bipEvent)
}
// console.log('Added event listener for beforeinstallprompt')
window.addEventListener('beforeinstallprompt', listener)

// setTimeout(async () => {
//   if ('getInstalledRelatedApps' in window.navigator) {
//     const relatedApps = await navigator.getInstalledRelatedApps()
//     relatedApps.forEach(app => {
//       console.log(app.platform, app.url)
//     })
//   } else {
//     console.log('getInstalledRelatedApps() not supported')
//   }
// }, 1e4)

export default deferred
