import axios from 'axios'
import { stringify } from 'qs'
import { push } from 'connected-react-router'
import { replace } from '../../../helpers/immutable'
import { setSnackbar } from '../snackbar'
import { setAuthenticationData } from '../login'

export const defaultFilter = {
  orderBy: null,
  order: 'asc',
  query: null
}

const initialState = {
  list: [],
  user: null,
  organisations: [],
  filter: defaultFilter,
  error: null,
  isLoadingFetch: false,
  isLoadingOrganisationsFetch: false,
  isLoadingRemove: false,
  isLoadingCreateOrUpdate: false,
  isLoadingImpersonate: false,
  isOpen: false
}

/* CLEAR_STATE Action */
const CLEAR_STATE = 'CLEAR_STATE'
const clearState = () => ({
  type: CLEAR_STATE
})

/* TOGGLE_MODAL Action */
const TOGGLE_MODAL = 'users/TOGGLE_MODAL'
export const toggle = user => ({
  type: TOGGLE_MODAL,
  payload: user
})

/* HANDLE_CHANGE Action */
const HANDLE_CHANGE = 'users/HANDLE_CHANGE'
export const handleChange = user => ({
  type: HANDLE_CHANGE,
  payload: user
})

/* HANDLE_FILTER Action */
const HANDLE_FILTER = 'users/HANDLE_FILTER'
export const handleFilter = filter => ({
  type: HANDLE_FILTER,
  payload: filter
})

/* ADMIN_FETCH_ORGANISATIONS Action */
const FETCH_ORGANISATIONS_START = 'users/FETCH_ORGANISATIONS_START'
const fetchOrganisationsStart = () => ({
  type: FETCH_ORGANISATIONS_START
})

const FETCH_ORGANISATIONS_FAIL = 'users/FETCH_ORGANISATIONS_FAIL'
const fetchOrganisationsFail = err => ({
  type: FETCH_ORGANISATIONS_FAIL,
  payload: err
})

const FETCH_ORGANISATIONS_SUCCESS = 'users/FETCH_ORGANISATIONS_SUCCESS'
const fetchOrganisationsSuccess = organisations => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload: organisations
})

export const fetchOrganisations = () => async dispatch => {
  dispatch(fetchOrganisationsStart())
  try {
    const response = await axios.get(
      '/v1/organisations/?&orderBy=name&order=asc'
    )
    dispatch(fetchOrganisationsSuccess(response.data))
  } catch (err) {
    dispatch(fetchOrganisationsFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* FETCH_ALL Action */
const FETCH_ALL_START = 'users/FETCH_ALL_START'
const fetchAllStart = () => ({
  type: FETCH_ALL_START
})

const FETCH_ALL_FAIL = 'users/FETCH_ALL_FAIL'
const fetchAllFail = err => ({
  type: FETCH_ALL_FAIL,
  payload: err
})

const FETCH_ALL_SUCCESS = 'users/FETCH_ALL_SUCCESS'
const fetchAllSuccess = list => ({
  type: FETCH_ALL_SUCCESS,
  payload: list
})

export const fetchAll = newFilter => async (dispatch, getState) => {
  const state = getState()
  const { user } = state.login
  const { filter } = state.userList

  let sort = stringify(filter)
  if (newFilter) {
    sort = stringify(newFilter)
    dispatch(handleFilter(newFilter))
  }
  const url = `/v1/organisations/${user.organisation.id}/users?${sort}`
  dispatch(fetchAllStart())
  try {
    const response = await axios.get(url)
    dispatch(fetchAllSuccess(response.data))
  } catch (err) {
    dispatch(fetchAllFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* CREATE_USER Action */
const CREATE_USER_START = 'CREATE_USER_START'
const createUserStart = () => ({
  type: CREATE_USER_START
})

const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
const createUserFail = err => ({
  type: CREATE_USER_FAIL,
  payload: err
})

const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
const createUserSuccess = body => ({
  type: CREATE_USER_SUCCESS,
  payload: body
})

export const create = body => async dispatch => {
  dispatch(createUserStart())
  try {
    const response = await axios.post(
      `/v1/organisations/${body.organisationId}/users`,
      body
    )
    dispatch(createUserSuccess(response.data))
    dispatch(
      setSnackbar(
        `En inbjudan har skickats till ${response.data.email}`,
        'success'
      )
    )
  } catch (err) {
    dispatch(createUserFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* UPDATE_USER Action */
const UPDATE_USER_START = 'UPDATE_USER_START'
const updateUserStart = () => ({
  type: UPDATE_USER_START
})

const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
const updateUserFail = err => ({
  type: UPDATE_USER_FAIL,
  payload: err
})

const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
})

export const update = user => async dispatch => {
  dispatch(updateUserStart())
  try {
    await axios.patch(`/v1/users/${user.id}`, user)
    dispatch(updateUserSuccess(user))
    dispatch(setSnackbar(`Användare ${user.email} har uppdaterats`, 'success'))
  } catch (err) {
    dispatch(updateUserFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* REMOVE_USER Action */
const REMOVE_USER_START = 'REMOVE_USER_START'
const removeUserStart = () => ({
  type: REMOVE_USER_START
})

const REMOVE_USER_FAIL = 'REMOVE_USER_FAIL'
const removeUserFail = err => ({
  type: REMOVE_USER_FAIL,
  payload: err
})

const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
const removeUserSuccess = id => ({
  type: REMOVE_USER_SUCCESS,
  payload: id
})

export const remove = user => async dispatch => {
  dispatch(removeUserStart())
  try {
    await axios.delete(
      `/v1/organisations/${user.organisation.id}/users/${user.id}`
    )
    dispatch(removeUserSuccess(user.id))
    dispatch(setSnackbar(`Användare ${user.email} har tagits.bort`, 'success'))
  } catch (err) {
    dispatch(removeUserFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

/* IMPERSONATE_USER Action */
const IMPERSONATE_USER_START = 'users/IMPERSONATE_USER_START'
const impersonateUserStart = () => ({
  type: IMPERSONATE_USER_START
})

const IMPERSONATE_USER_FAIL = 'users/IMPERSONATE_USER_FAIL'
const impersonateUserFail = err => ({
  type: IMPERSONATE_USER_FAIL,
  payload: err
})

const IMPERSONATE_USER_SUCCESS = 'users/IMPERSONATE_USER_SUCCESS'
// data contains token, user and expires.
const impersonateUserSuccess = data => ({
  type: IMPERSONATE_USER_SUCCESS,
  payload: data
})

export const impersonate = (userId, orgId) => async dispatch => {
  dispatch(impersonateUserStart())
  try {
    const response = await axios.get(`/v1/impersonate/${orgId}/${userId}`)
    dispatch(impersonateUserSuccess(response.data))
    dispatch(clearState())
    dispatch(setAuthenticationData(response.data))
  } catch (err) {
    dispatch(impersonateUserFail(err))
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
  dispatch(push('/dashboard'))
}

const RESEND_INVITATION_LINK_START = 'RESEND_INVITATION_LINK_START'
const resendInvitationLinkStart = () => ({
  type: RESEND_INVITATION_LINK_START
})

const RESEND_INVITATION_LINK_FAIL = 'RESEND_INVITATION_LINK_FAIL'
const resendInvitationLinkFail = () => ({
  type: RESEND_INVITATION_LINK_FAIL
})

const RESEND_INVITATION_LINK_SUCCESS = 'RESEND_INVITATION_LINK_SUCCESS'
const resendInvitationLinkSuccess = () => ({
  type: RESEND_INVITATION_LINK_SUCCESS
})

export const resendInvitationLink = user => async dispatch => {
  dispatch(resendInvitationLinkStart())
  try {
    await axios.post(`/v1/users/${user.id}/resend`, user)
    dispatch(resendInvitationLinkSuccess())
  } catch (err) {
    dispatch(resendInvitationLinkFail())
    dispatch(
      setSnackbar(
        err.response ? global._(err.response.data) : global._(err.message),
        'error'
      )
    )
  }
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL: {
      if (state.isOpen) return { ...state, isOpen: false, user: null }
      return { ...state, isOpen: true, user: action.payload }
    }
    case HANDLE_CHANGE:
      return { ...state, user: action.payload }
    case HANDLE_FILTER:
      return { ...state, filter: action.payload }
    case FETCH_ALL_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ALL_FAIL:
      return { ...state, isLoadingFetch: false, error: action.payload }
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isLoadingFetch: false,
        list: action.payload,
        error: null
      }
    case CREATE_USER_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_USER_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case CREATE_USER_SUCCESS: {
      const list = [...state.list]
      list.push(action.payload)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        error: null,
        user: null,
        isOpen: false
      }
    }
    case UPDATE_USER_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        error: action.payload
      }
    case UPDATE_USER_SUCCESS: {
      const user = action.payload
      if (user.organisation) {
        const organisation = state.organisations.find(
          foundOrg => user.organisationId === foundOrg.id
        )
        user.organisation.id = organisation.id
        user.organisation.name = organisation.name
      }
      const list = replace({ id: user.id }, user, state.list)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        error: null,
        user: null,
        isOpen: false
      }
    }
    case REMOVE_USER_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_USER_FAIL:
      return { ...state, isLoadingRemove: false, error: action.payload }
    case REMOVE_USER_SUCCESS: {
      const list = replace({ id: action.payload }, null, [...state.list])
      return {
        ...state,
        isLoadingRemove: false,
        list,
        error: null
      }
    }
    case IMPERSONATE_USER_START:
      return { ...state, isLoadingImpersonate: true }
    case IMPERSONATE_USER_FAIL:
      return {
        ...state,
        isLoadingImpersonate: false,
        error: action.payload
      }
    case IMPERSONATE_USER_SUCCESS: {
      const { user, token, expires } = action.payload
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token)
      localStorage.setItem('expires', expires)
      return { ...state, isLoadingImpersonate: false, error: null }
    }
    case FETCH_ORGANISATIONS_START:
      return { ...state, isLoadingOrganisationsFetch: true }
    case FETCH_ORGANISATIONS_FAIL:
      return {
        ...state,
        isLoadingOrganisationsFetch: false,
        error: action.payload
      }
    case FETCH_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        isLoadingOrganisationsFetch: false,
        organisations: action.payload,
        error: null
      }
    case RESEND_INVITATION_LINK_START:
      return { ...state, isLoadingResendInvitationLink: true }
    case RESEND_INVITATION_LINK_FAIL:
      return {
        ...state,
        isLoadingResendInvitationLink: false,
        error: action.payload
      }
    case RESEND_INVITATION_LINK_SUCCESS:
      return { ...state, isLoadingResendInvitationLink: false }
    case 'CLEAR_STATE':
      return state
    default:
      return state
  }
}

export default users
